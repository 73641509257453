import { useGetRecipesByByIdQuery, usePostSalesCreateMutation } from "libs/store/recipeApi";
import { TokenGfx } from "components/atoms/TokenGfx/TokenGfx";
import styles from "./RecipeDetail.module.scss";
import { Divider } from "components/atoms/Divider/Divider";
import { TwoIs } from "components/organisms/TwoIs/TwoIs";
import { YoutubeEmbed } from "components/organisms/OnboardingContainer/YoutubeEmbed";
import { OptionalTextLine, SemiOvalButton } from "components/atoms";
import { LoginProtectedAction } from "components/atoms/LoginProtectedAction";
import { EditRecipeButton } from "components/organisms/EditRecipeButton/EditRecipeButton";
import { Link } from "react-router-dom";

export const RecipeDetail = ({dbId}: { dbId: string }) => {

    const {data: recipeData} = useGetRecipesByByIdQuery({id: dbId}, {skip: !dbId});


    const creatorLabel = recipeData?.creator?.name && <>by {recipeData.creator.name}</>;
    usePostSalesCreateMutation()


    return (
        <>
            <div className={styles.RecipeDetail}>
                {recipeData && <>
                    <div className={styles.title}>
                        <div>{recipeData.name} {creatorLabel}</div>
                        <EditRecipeButton recipe={recipeData}/>
                    </div>
                    <LoginProtectedAction fallbackText={"Login to Buy"} customBackLink={`/recipe/${dbId}/buy`}>
                        <Link to={`/recipe/${dbId}/buy`}><SemiOvalButton text={"Buy"}/></Link>
                    </LoginProtectedAction>
                    <Divider/>
                    <TokenGfx recipe={recipeData}/>

                    <div className={styles.fansection}>
                        <div className={styles.fan}>TODO show top fan</div>
                        <div className={styles.fan}>Element 2</div>
                        <div className={styles.fan}>Element 3</div>
                        <div className={styles.fan}>Element 4</div>
                        <div className={styles.fan}>Element 5</div>
                        <div className={styles.fan}>Element 6</div>
                        <div className={styles.fan}>Element 7</div>
                        <div className={styles.fan}>Element 8</div>
                        <div className={styles.fan}>Element 9</div>
                        <div className={styles.fan}>Element 10</div>
                    </div>

                    <TwoIs recipe={recipeData}/>
                    <YoutubeEmbed videoId={recipeData.youtubeVideoId}/>
                    <OptionalTextLine text={recipeData.secret_hint} multiline/>
                </>}

            </div>
        </>
    );
};

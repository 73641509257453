import { ReactNode } from 'react';
import { useIsTokenBasedAuthorized } from 'libs';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { SemiOvalButton } from 'components/atoms/SemiOvalButton';

export const LoginProtectedAction = (props: {
    children: ReactNode;
    fallbackText?: string;
    customBackLink?: string;
}) => {
    const isAuth = useIsTokenBasedAuthorized();
    const location = useLocation();
    const backLink = encodeURIComponent(
        props.customBackLink || location.pathname + location.search
    );

    return (
        <>
            {isAuth ? (
                props.children ? (
                    props.children
                ) : (
                    <></>
                )
            ) : (
                <Link to={`/login?backLink=${backLink}`}>
                    <SemiOvalButton text={props.fallbackText || 'Login'}/>
                </Link>
            )}
        </>
    );
};

// import { Divider, SemiOvalButton } from "..";
import styles from "./CardRecipeDescription.module.scss";

export type TCardRecipeDescription = {
    text?: string;
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CardRecipeDescription = (props: TCardRecipeDescription) => {
    // const { text } = props;
    console.log("props", props)
    return (
        <div className={styles.cardRecipeWithVideoWrapper}>
            <p className={styles.cardRecipeText}>
                Bring water to a boil to cook noodles.
                <br />
                <br />
                Combine all the ingredients for the sauce in a mixing bowl, and
                set aside. If you want to keep it for later, transfer it to an
                air-tight container and keep it in a refrigerator. This sauce
                will last up to a month in the fridge.
                <br />
                <br />
                Cook noodles by following the directions of the package you’re
                using. Drain cooked noodles and place them in a mixing bowl.
                <br />
                <br />
                Pour the sauce and toss together until every noodle is coated
                evenly with the sauce. Transfer to a serving plate and place the
                julienned cucumber on one side of the noodles.
                <br />
                <br />
                Grind sesame seeds in a motor and pestle and scoop on top of the
                noodles. Serve immediately.
                <br />
                <br />
                Enjoy! Bring water to a boil to cook noodles.
                <br />
                <br />
                Combine all the ingredients for the sauce in a mixing bowl, and
                set aside. If you want to keep it for later, transfer it to an
                air-tight container and keep it in a refrigerator. This sauce
                will last up to a month in the fridge.
                <br />
                <br />
                Cook noodles by following the directions of the package you’re
                using. Drain cooked noodles and place them in a mixing bowl.
                <br />
                <br />
                Pour the sauce and toss together until every noodle is coated
                evenly with the sauce. Transfer to a serving plate and place the
                julienned cucumber on one side of the noodles.
                <br />
                <br />
                Grind sesame seeds in a motor and pestle and scoop on top of the
                noodles. Serve immediately.
                <br />
                <br />
                Enjoy!
            </p>
        </div>
    );
};

import { useGetRecipesByByIdQuery } from "libs/store/recipeApi";
import { TokenGfx } from "components/atoms/TokenGfx/TokenGfx";
import styles from "components/organisms/BuyRecipe/BuyRecipel.module.scss";
import { Divider } from "components/atoms/Divider/Divider";
import { ErrorMessage, Formik } from "formik";
import { SemiOvalButton, TextInput } from "components/atoms";

export const BuyRecipeSuccess = ({dbId}: { dbId: string, success: string, saleId: string }) => {

    const {data: recipeData} = useGetRecipesByByIdQuery({id: dbId}, {skip: !dbId});

    const creatorLabel = recipeData?.creator?.name && <>by {recipeData.creator.name}</>;

    function handleSubmitComment() {
        console.log("TODO post comment to recipe")
    }

    return (
        <>
            <div className={styles.RecipeDetail}>
                {recipeData && <>
                    <div>{recipeData.name} {creatorLabel}</div>
                    <Divider/>
                    <TokenGfx recipe={recipeData}/>
                    Success!
                    <Formik initialValues={{comment: ""}}
                            onSubmit={handleSubmitComment}>
                        {({handleChange, handleSubmit, handleBlur, values}) => (
                            <form
                                onSubmit={handleSubmit}
                                className={styles.login}
                            >
                                <div>Note: This comment will be publicly visible</div>
                                <TextInput
                                    text={`Let ${recipeData?.creator?.name} know what you think!`}
                                    type="textarea"
                                    name="comment"
                                    value={values.comment}
                                    onChange={handleChange("comment")}
                                    onBlur={handleBlur}
                                />
                                <div className={styles.erroMessage}>
                                    <ErrorMessage name="comment" className={styles.erroMessage}/>
                                </div>
                                <SemiOvalButton text={"Post Comment"}/>

                            </form>
                        )}

                    </Formik>
                </>}
            </div>
        </>
    );
};

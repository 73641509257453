import styles from "./IngredientsText.module.scss";


const quantifiers = ["stk", "tbsp", "gr", "kg"]

function extractIngredients(line: string) {
    const segments = line.split(" ");

    let quantity = "";

    function startsWithLetter() {
        const firstChar = segments[0].charAt(0);
        return firstChar.toLowerCase() != firstChar.toUpperCase()
    }

    while (segments.length > 0 && !startsWithLetter()) {
        quantity += segments.shift() ?? "";
    }

    let unit = "";
    if (quantifiers.includes(segments[0]?.toLowerCase())) {
        unit = segments.shift() ?? "";
    }
    const ingredient = segments.join(" ");
    return {quantity, unit, ingredient};
}

export const IngredientsText = ({text}: {
    text: string
}) => {
    const lines = text.split("\n");

    return <table className={styles.IngredientsTable}>
        <tbody>
        {lines.map((line, index) => {
            const parsed = extractIngredients(line);

            const {quantity, unit, ingredient} = parsed;

            return (
                <tr key={index}>
                    <td className={styles.quantity}>{quantity}</td>
                    <td className={styles.unit}>{unit}</td>
                    <td className={styles.ingredient}>{ingredient}</td>
                </tr>
            );
        })}
        </tbody>
    </table>
}

import { GetBasicRecipesDto, GetOnboardingInfoDto } from "libs/store/recipeApi";
import { RecipeCard } from "..";
import { Divider, SemiOvalButton } from "../../atoms";
import styles from "./RecipePreview.module.scss";

export type TRecipePreview = {
  titleText: string;
  footerText: string;
  imageUrl: string;
  recipe: GetBasicRecipesDto;
  creator: GetOnboardingInfoDto;
  registerAction: () => void;
};

export const RecipePreview = (props: TRecipePreview) => {
  const { titleText, footerText, imageUrl, recipe, creator, registerAction } =
    props;

  return (
    <div className={styles.cardDisplayPreviewWrapper}>
      <div className={styles.cardDisplayPreviewContent}>
        <div className={styles.cardDisplayPreviewImageContainer}>
          <div className={styles.cardDisplayPreviewBigImageContainer}>
            <RecipeCard disableButton recipe={recipe} creator={creator} />
          </div>
          <div className={styles.cardDisplayPrevieMediumImageContainer}>
            <img className={styles.cardDisplayPreviewImage} src={imageUrl} />
          </div>
        </div>
        <div className={styles.cardDisplayPreviewTextContainer}>
          <p className={styles.cardDisplayPreviewTitleText}>{titleText}</p>
          <div className={styles.cardDisplayPreviewActionButton}>
            <SemiOvalButton text={"Login"} onClick={registerAction} />
          </div>
        </div>
        <Divider />
        <div className={styles.cardDisplayPreviewFooter}>
          <p className={styles.cardDisplayPreviewFooterText}>{footerText}</p>
        </div>
      </div>
    </div>
  );
};

import { Header, LoginContainer } from "../../organisms";

export const LoginTemplate = (props: { backLink?: string }) => {
    return (
        <>
            <Header noLogin={true}/>
            <LoginContainer backLink={props.backLink}/>
        </>
    );
};

import { TextInput } from "components/atoms/TextInput";

export function OptionalTextLine(props: { text?: string, multiline?: boolean }) {
    return <>
        {props.text ? <TextInput
            name={"readonly"}
            disabled
            text={props.text}
            type={props.multiline?"textarea":"text"}/> : <></>}
    </>;
}

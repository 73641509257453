import { SemiOvalButton, TextInput } from "../../atoms";
import styles from "./AdminContainer.module.scss";
import { useAdminCreateCIForm } from "components/organisms/AdminContainer/useAdminCreateCIForm";
import { Link } from "react-router-dom";
import { getUrlConfig } from "libs/utils";
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/webcam/dist/style.min.css';
import { ImageUploader } from "components/organisms/ImageUploader/ImageUploader";


const CustomErrorMessage = ({name, formik}: { name: string, formik: any }) => {
    const {errors, touched} = formik;
    if (errors[name] && touched[name]) {
        return <div>{errors[name]}</div>;
    }
    return null;
};

export const AdminContainer = () => {

    const {
        formik,
        idResult,
        triggerCrawlAndWait,
        hasValidVideoUrl,
        crawlMightBeFinished,
        triggerTransfer,
        allowSubmit,

    } = useAdminCreateCIForm();
    const {values, handleChange, handleBlur} = formik;

    const inviteLinkPath = `/exclusiveAccess/${idResult?.id}/${idResult?.inviteCode}`;
    const {frontendBaseUrl} = getUrlConfig();

    const handleUploadSuccess = (fileId: string) => {
        formik.setFieldValue('raw_token_image_id', fileId);
    };

    return <form
        onSubmit={formik.handleSubmit}
        className={styles.login}
    >
        <div className={styles.loginContainer}>
            <CustomErrorMessage formik={formik} name="youtubeVideoUrl"/>
            <TextInput
                text={"Youtube Video Link"}
                type="text"
                name="youtubeVideoUrl"
                value={values.youtubeVideoUrl}
                onChange={handleChange("youtubeVideoUrl")}
                onBlur={handleBlur}
            />

            <br className={styles.indent}></br>
            <div className={styles.loginContainerButtons}>
                <SemiOvalButton
                    onClick={triggerCrawlAndWait}
                    disabled={!hasValidVideoUrl}
                    text={"Start Loading Infos"}
                    theme={"dark"}
                    type={"right"}
                    size={"middle"}
                />
            </div>
            {crawlMightBeFinished && <>
                <br className={styles.indent}></br>
                <a onClick={triggerTransfer}>
                    <SemiOvalButton
                        disabled={!crawlMightBeFinished}
                        text={"Transfer Data"}
                        theme={"dark"}
                        type={"right"}
                        size={"middle"}
                    />
                </a>
                <br className={styles.indent}></br>


                <CustomErrorMessage formik={formik} name="name"/>
                <TextInput
                    text={"Culinary Influencer Name"}
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange("name")}
                    onBlur={handleBlur}
                />

                <CustomErrorMessage formik={formik} name="onboardingEmail"/>
                <TextInput
                    text={"Onboarding Email Address"}
                    type="text"
                    name="onboardingEmail"
                    value={values.onboardingEmail}
                    onChange={handleChange("onboardingEmail")}
                    onBlur={handleBlur}
                />

                <CustomErrorMessage formik={formik} name="recipeName"/>
                <TextInput
                    text={"Initial Recipe Name"}
                    type="text"
                    name="recipeName"
                    value={values.recipeName}
                    onChange={handleChange("recipeName")}
                    onBlur={handleBlur}
                />

                <CustomErrorMessage formik={formik} name="profilePictureCIUrl"/>
                <TextInput
                    text={"Url of the profile picture"}
                    type="text"
                    name="profilePictureCIUrl"
                    value={values.profilePictureCIUrl}
                    onChange={handleChange("profilePictureCIUrl")}
                    onBlur={handleBlur}
                />

                <div>Recipe token graphics image</div>
                <input type="hidden" name={"raw_token_image_id"} value={values.raw_token_image_id}/>
                <ImageUploader onUploadSuccess={handleUploadSuccess} />

                <br className={styles.indent}></br>
                <CustomErrorMessage formik={formik} name="ingredients"/>
                <TextInput
                    text={"Ingredients"}
                    type="textarea"
                    name="ingredients"
                    value={values.ingredients}
                    onChange={handleChange("ingredients")}
                    onBlur={handleBlur}
                />

                <CustomErrorMessage formik={formik} name="instructions"/>
                <TextInput
                    text={"Preparation"}
                    type="textarea"
                    name="instructions"
                    value={values.instructions}
                    onChange={handleChange("instructions")}
                    onBlur={handleBlur}
                />

                <br className={styles.indent}></br>
                <div className={styles.loginContainerButtons}>
                    <SemiOvalButton
                        disabled={!allowSubmit}
                        text={"Create Influencer"}
                        theme={"dark"}
                        type={"right"}
                        size={"middle"}
                    />
                </div>
            </>}
            {idResult && <div>
                <div>Creator id:: {idResult.id}<br/>
                </div>
                <div>Invite Code: <br/>{idResult.inviteCode}<br/>
                </div>
                <div>Link: <br/>{`${frontendBaseUrl}${inviteLinkPath}`}
                </div>

                <div><Link to={inviteLinkPath}>Check me!</Link></div>

            </div>}
        </div>

    </form>
};

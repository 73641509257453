import { MenuButton } from "components/atoms";
import styles from "./MenuContainer.module.scss";
import logoHeader from "../../../styles/images/logo_header.png";
import { Link } from 'react-router-dom';

type MenuProps = {
    isOpen: boolean;
    setOpen: (_: boolean) => void;
}

export const MenuContainer = (props: MenuProps) => {
    const { isOpen, setOpen } = props;

    return (
        <div className={styles.menuContainer}>
            <div className={styles.menuContainerTop}>
                <MenuButton setOpen={setOpen} isOpen={isOpen} />
                <div className={styles.menuContainerRoutes}>
                    <div className={styles.linksBlock}>
                        <Link to="/">
                            <p className={styles.link}>Home</p>
                        </Link>
                        <p className={styles.link}>About</p>
                        <p className={styles.link}>Contact</p>
                    </div>
                    <div className={styles.linksBlock}>
                        <Link to="/profile">
                            <p className={styles.link}>Profile</p>
                        </Link>
                        <p className={styles.subLink}>Create Recipe</p>
                        <p className={styles.subLink}>My Recipes</p>
                        <p className={styles.subLink}>My Favorits</p>
                        <p className={styles.subLink}>Edit Profile</p>
                    </div>
                    <div className={styles.linksBlock}>
                        <p className={styles.link}>Settings</p>
                        <p className={styles.subLink}>Edit Profile</p>
                        <p className={styles.subLink}>Edit Channel</p>
                        <p className={styles.subLink}>Passwort</p>
                        <p className={styles.subLink}>Reset</p>
                    </div>
                    <div className={styles.linksBlock}>
                        <p className={styles.link}>FAQ</p>
                        <p className={styles.link}>Logout</p>
                    </div>
                </div>
            </div>
            <div className={styles.menuContainerBottom}>
                <div className={styles.footerImage}>
                    <img src={logoHeader} alt="logo" />
                </div>
                <p className={styles.footerText}>The world’s first and largest digital marketplace for crypto recipes. Buy, sell, and discover exclusive digital items.</p>
            </div>
        </div>
    );
}
import { createApi } from "@reduxjs/toolkit/query/react";
import { buildBaseQueryWithReauthFunc } from "../utils";

export const RECIPE_API_KEY = "recipeApi";

export const emptySplitApi = createApi({
    baseQuery: buildBaseQueryWithReauthFunc(),
    reducerPath: RECIPE_API_KEY,
    refetchOnFocus: false,
    refetchOnMountOrArgChange: true,
    endpoints: () => ({}),
});

import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError, retry, } from "@reduxjs/toolkit/dist/query";
import { Mutex } from "async-mutex";
import { setAuthTokenToRequest } from "../store/auth.store";
import { getUrlConfig } from "libs/utils/getUrlConfig";

const mutex = new Mutex();

const RETRIES_COUNT = 0;

const {publicApiUri} = getUrlConfig();
export const buildBaseQueryWithReauthFunc = (): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> => {
    return async (args, api, extraOptions) => {
        const baseQuery = retry(
            fetchBaseQuery({
                baseUrl: publicApiUri,
                prepareHeaders: setAuthTokenToRequest,
            }),
            {maxRetries: RETRIES_COUNT}
        );

        // wait until the mutex is available without locking it
        await mutex.waitForUnlock();

        let result = await baseQuery(args, api, extraOptions);

        if (result.error) {
            const isNotAuthorized = [401, 422].includes(result.error.status as unknown as number);

            const errorMessage = ((result.error?.data as any)?.message as string)?.toLowerCase();
            const isTokenMissing = errorMessage?.includes("no auth token");
            const isInvalidSignature = errorMessage?.includes("invalid signature");
            const isJwtExpired = errorMessage?.includes("jwt expired");

            const isTokenError = Boolean(
                isNotAuthorized ||
                isTokenMissing ||
                isInvalidSignature ||
                isJwtExpired
            );

            if (!isTokenError) {
                return result;
            }

            // checking whether the mutex is locked
            if (!mutex.isLocked()) {
                const release = await mutex.acquire();
                // api.dispatch(apiLogout());
                release();
            } else {
                // wait until the mutex is available without locking it
                await mutex.waitForUnlock();
                result = await baseQuery(args, api, extraOptions);
            }
        }
        const resultHack = result as any; //extract the relevant section from result
        return resultHack.error || resultHack.data;
    };
};

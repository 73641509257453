import styles from "./RecipesList.module.scss";
import { RecipeCard } from "../../molecules";
import { GetRecipeDto } from "libs/store/recipeApi";

export const RecipesList = ({data}: { data?: GetRecipeDto[] }) => {

    return (
        <>
            <div className={styles.recipesList}>
                {data && data.map((recipe, i) => {
                    if (!recipe.creator) {
                        throw new Error("creator missing from recipe")
                    }
                    return (
                        <div key={i}>
                                <RecipeCard recipe={recipe} creator={recipe.creator}/>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

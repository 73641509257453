import styles from "components/organisms/EditRecipeButton/EditRecipeButton.module.scss";
import { EditSymbol } from "components/atoms/Symbols/EditSymbol";
import { selectAuth, useAppSelector } from "libs";
import { GetRecipeDto } from "libs/store/recipeApi";
import { Link } from "react-router-dom";

export function EditRecipeButton({recipe}: { recipe: GetRecipeDto }) {

    const auth = useAppSelector(selectAuth);
    console.log("auth.controlled_culinary_influencer_id", auth?.controlled_culinary_influencer_id)
    if (!recipe?.creator || recipe.creator.id !== auth.controlled_culinary_influencer_id) {
        return <></>
    }
    return <Link to={`/recipe/${recipe.id}/edit`}>
        <div className={styles.EditButton}><EditSymbol/></div>
    </Link>;
}

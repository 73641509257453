import { Header } from "components/organisms";
import styles from "./PlanC.module.scss";
import { ErrorMessage, Form, Formik } from "formik";
import { SurveyPayloadDto, usePostSurveyMutation } from "libs/store/recipeApi";
import { useState } from "react";
import { CheckBox, SemiOvalButton, TextInput } from "components/atoms";
import { Select } from "components/atoms/Select/Select";
import * as Yup from 'yup';


export default function PlanC() {
    const [postData] = usePostSurveyMutation();
    const [submitting, setSubmitting] = useState(false);
    const [submissionSuccessful, setSubmissionSuccessful] = useState(false);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
    });

    const initialValues: SurveyPayloadDto = {
        name: "",
        email: "",
        favouriteCook: "",
        role: "Fan",
        comment: "",
        notifyMe: false,
    };

    const submitData = async (values: SurveyPayloadDto) => {
        try {
            await postData({surveyPayloadDto: values}).unwrap();
            setSubmissionSuccessful(true);
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    return (
        <div className={styles.PlanC}>
            <Header noLogin noMenu/>
            {!submissionSuccessful && (
                <Formik initialValues={initialValues}
                        onSubmit={submitData}
                        validationSchema={validationSchema}
                >
                    {({handleChange, handleBlur, values, handleSubmit, isValid, touched}) => (
                        <Form className={styles.formContainer}>
                            <h1><strong>rare.recipes</strong> is gearing up for an exciting launch!</h1>
                            <div>
                                We'd love to hear your suggestions for talented chefs, food bloggers, recipe developers,
                                and other VIPs in the culinary world you'd like to see on our platform.
                            </div>
                            <div>
                                Share your favorite culinary creators with us and help us create a deliciously diverse
                                community!
                            </div>
                            <TextInput
                                text="Your Name"
                                type="text"
                                name="name"
                                onChange={handleChange("name")}
                                onBlur={handleBlur}
                            />
                            <ErrorMessage name="email"/>
                            <TextInput
                                text="Email *"
                                type="email"
                                name="email"
                                onChange={handleChange("email")}
                                onBlur={handleBlur}
                            />

                            <TextInput
                                text="Favourite Culinary Content Creator (optional)"
                                type="text"
                                name="favouriteCook"
                                onChange={handleChange("favouriteCook")}
                                onBlur={handleBlur}
                            />

                            <div className={styles.dropdown}>
                                <label htmlFor="role">How would you like to use rare.recipes?</label>
                                <Select
                                    name="role"
                                    value={values.role || "Fan"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    options={[
                                        "Culinary Content Creator",
                                        "Fan",
                                        "Special (please comment!)",
                                    ]}
                                />
                            </div>

                            <TextInput
                                text="Let us know what you think.."
                                type="textarea"
                                name="comment"
                                onChange={handleChange("comment")}
                                onBlur={handleBlur}
                            />
                            <CheckBox label={"Notify me when rare.recipes is ready"} id={"notifyMe"}
                                      checked={values.notifyMe} onChange={handleChange("notifyMe")}/>

                            <div className={styles.lastLine}>

                                <SemiOvalButton
                                    onClick={() => {
                                        setSubmitting(true)
                                        handleSubmit()
                                    }
                                    }
                                    text={"Submit"}
                                    theme={"dark"}
                                    type={"right"}
                                    size={"middle"}
                                    disabled={!(isValid && touched) || submitting}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
            {submissionSuccessful && (
                <div className={styles.infoBox}>
                    <p>Thank you for your submission!</p>
                </div>
            )}
        </div>
    );
}

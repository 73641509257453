import { configureStore } from "@reduxjs/toolkit";
import { combineReducers, StateFromReducersMapObject } from "redux";
import {
    FLUSH,
    PAUSE,
    PERSIST,
    persistReducer,
    persistStore,
    PURGE,
    REGISTER,
    REHYDRATE,
} from "redux-persist";
import localStorage from "redux-persist/lib/storage";
import { emptySplitApi, RECIPE_API_KEY } from "./emptyApi";
import { AUTH_SLICE_KEY, authSlice } from "./auth.store";
import thunkMiddleware from "redux-thunk";

const reducer = {
    [RECIPE_API_KEY]: emptySplitApi.reducer,
    [AUTH_SLICE_KEY]: authSlice.reducer,
};

const rootReducer = combineReducers(reducer);

const persistConfig = {
    key: "root",
    storage: localStorage,
    blacklist: [/*STAKE_SLICE_KEY, BENEFITS_SLICE_KEY*/], //add keys we don't want to persist
    debug: process.env.NODE_ENV === "development",
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }).concat(
            /**
             * When using Redux Persist without using the Thunk middleware,
             * we‘d get an error in the browser’s console
             * "reading a non-serializable value was detected in the state".
             */
            thunkMiddleware,
            emptySplitApi.middleware,
        );
    },
    devTools: process.env.NODE_ENV === "development",
});
export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = StateFromReducersMapObject<typeof reducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

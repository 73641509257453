import { OptionalTextLine, SemiOvalButton, TextInput } from "../../atoms";
import styles from "./RegisterContainer.module.scss";
import { useRegisterForm } from "../../hooks";
import { ErrorMessage, Formik } from "formik";
import {
    GetCulinaryInfluencersOnboardingByIdAndInviteCodeApiArg,
    useGetCulinaryInfluencersOnboardingByIdAndInviteCodeQuery
} from "libs/store/recipeApi";

export const RegisterContainer = (props: {
    backLink?: string;
    onboardingData?: {
        id: string;
        inviteCode: string;
    };
}) => {
    const { /*initialValues,*/ onRegister, validationSchema} = useRegisterForm(
        props.backLink,
        props.onboardingData
    );

    const queryData = {
        id: props.onboardingData?.id,
        inviteCode: props.onboardingData?.inviteCode,
    } as GetCulinaryInfluencersOnboardingByIdAndInviteCodeApiArg;
    const {data, /*isSuccess*/} =
        useGetCulinaryInfluencersOnboardingByIdAndInviteCodeQuery(
            queryData,
            {skip: !props.onboardingData}
        );

    const prefillData = data;


    return (
        <Formik
            initialValues={{
                name: prefillData?.name || "",
                email: prefillData?.email || "",
                password: "",
                onboardingData: props.onboardingData,
            }}
            onSubmit={onRegister}
            validationSchema={validationSchema}
        >
            {({handleChange, handleSubmit, handleBlur, values}) => (
                <form onSubmit={handleSubmit} className={styles.register}>
                    <div className={styles.registerContainer}>
                        <ErrorMessage name="name"/>
                        <TextInput
                            text={"Name"}
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange("name")}
                            onBlur={handleBlur}
                        />

                        <TextInput
                            text={"Email"}
                            type="text"
                            name="email"
                            value={values.email}
                            onChange={handleChange("email")}
                            onBlur={handleBlur}
                        />
                        <ErrorMessage name="email"/>

                        <TextInput
                            text={"Password"}
                            type="password"
                            name="password"
                            value={values.password}
                            onChange={handleChange("password")}
                            onBlur={handleBlur}
                        />
                        <ErrorMessage name="password"/>

                        <TextInput
                            text={"Confirm Password"}
                            type="password"
                            name="confirm_password"
                            onChange={handleChange("confirm_password")}
                            onBlur={handleBlur}
                        />
                        <ErrorMessage name="confirm_password"/>

                        <OptionalTextLine
                            text={props.onboardingData?.inviteCode}
                        />

                        <br className={styles.indent}></br>
                        <div className={styles.registerContainerButtons}>
                            <SemiOvalButton
                                text={"Register"}
                                theme={"dark"}
                                type={"right"}
                                size={"middle"}
                            />
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    );
};

import { useNavigate } from "react-router-dom";
import { apiLogin, useAppDispatch } from "libs";
import * as Yup from "yup";
import { LoginPayloadDto, PostAuthLoginApiArg, usePostAuthLoginMutation, } from "libs/store/recipeApi";

export const useLoginForm = (backLink?: string) => {
    const [loginRequest] = usePostAuthLoginMutation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object({
        email: Yup.string()
            .trim()
            .min(2, "Too Short!")
            .max(256, "Too Long!")
            .email("Incorrect email")
            .required("Required"),
        password: Yup.string()
            .trim()
            .min(5, "Too Short!")
            .max(50, "Too Long!")
            .required("Required"),
    });

    const initialLoginForm = {
        email: "",
        password: "",
    };

    const onSubmit = async (data: LoginPayloadDto) => {
        try {
            const payload: PostAuthLoginApiArg = {
                loginPayloadDto: data,
            };

            const result = await loginRequest(payload).unwrap();
            console.log("result", result)
            dispatch(apiLogin({
                email: result.email,
                access_token: result.access_token,
                refresh_token: result.refresh_token,
                controlled_culinary_influencer_id: result.controlled_culinary_influencer_id || null
            }));

            if (!result.email_confirmed) {
                const backLinkFragment = backLink ? `?backLink=${encodeURIComponent(backLink)}` : "";
                navigate(`/confirm-email/${result.id}${backLinkFragment}`)
            } else {
                navigate(backLink ?? "/");
            }
        } catch (error) {
            console.error(error);
        }
    };

    return {
        onSubmit,
        validationSchema,
        initialValues: initialLoginForm,
    };
};

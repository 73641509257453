import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import routes from "./routes";

/*import dotenv from 'dotenv';
dotenv.config();*/

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
      <BrowserRouter>
          <App>
              <Routes>
                  {routes.map((route, index) => (
                      <Route key={index} {...route} />
                  ))}
              </Routes>
          </App>
      </BrowserRouter>
  </React.StrictMode>
)

import classNames from "classnames";
import styles from "./TopFansCard.module.scss";

interface TopFansCard {
  id: number;
  title: string;
  personalNumber: number;
  fanNumber: string;
  photoUrl: string;
  commentText: string;
  commentDate: string;
}

type TTopFansCard = {
  fansData: TopFansCard[];
};

export const TopFansCard = (props: TTopFansCard) => {
  const { fansData } = props;

  return (
    <div className={styles.cardTopFansCardWrapper}>
      {fansData.map((card, index) => (
        <div
          key={card.id}
          className={classNames(
            index === 0 ? styles.cardTopFan : styles.cardTopFanMedium,
            index > 2 ? styles.cardTopFanSmall : null
          )}
        >
          <div className={classNames(styles.cardTopFanHeaderContainer)}>
            <div
              className={classNames(
                index === 0
                  ? styles.cardTopFanHeaderTitleContainer
                  : styles.cardTopFanHeaderTitleContainerMedium,
                index > 2 ? styles.cardTopFanHeaderTitleContainerSmall : null
              )}
            >
              <p
                className={classNames(
                  index === 0
                    ? styles.cardTopFanHeaderNumber
                    : styles.cardTopFanHeaderNumberMedium,
                  index > 2 ? styles.cardTopFanHeaderNumberSmall : null
                )}
              >
                {card.personalNumber}
                {index > 2 ? null : (
                  <span
                    className={classNames(
                      index < 1
                        ? styles.cardTopFanHeaderTitle
                        : styles.cardTopFanHeaderTitleMedium
                    )}
                  >
                    {card.title}
                  </span>
                )}
              </p>
              <div
                className={classNames(
                  index === 0
                    ? styles.cardTopFanHeaderPhotoContainer
                    : styles.cardTopFanHeaderPhotoContainerMedium,
                  index > 2 ? styles.cardTopFanHeaderPhotoContainerSmall : null
                )}
              >
                <img
                  className={classNames(styles.cardTopFanHeaderPhoto)}
                  src={card.photoUrl}
                />
              </div>
            </div>
          </div>
          <div
            className={classNames(
              index < 3
                ? styles.cardTopFanFooterContainer
                : styles.cardTopFanFooterContainerSmall
            )}
          >
            <p
              className={classNames(
                index === 0
                  ? styles.cardTopFanFooterTitle
                  : styles.cardTopFanFooterTitleMedium,
                index > 2 ? styles.cardTopFanFooterTitleSmall : null
              )}
            >
              Fan #{card.fanNumber}
            </p>
            {index < 3 ? (
              <div
                className={classNames(
                  index === 0
                    ? styles.cardTopFanFooterCommentContainer
                    : styles.cardTopFanFooterCommentContainerMedium
                )}
              >
                <p className={classNames(styles.cardTopFanFooterCommentText)}>
                  {card.commentText}
                </p>
                {index === 0 ? (
                  <p className={classNames(styles.cardTopFanFooterCommentDate)}>
                    {card.commentDate}
                  </p>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
};

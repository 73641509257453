import { AdminContainer, Header } from "components/organisms";

export const AdminTemplate = () => {
    return (
        <>
            <Header />
            <AdminContainer />
        </>
    );
};

import { RegisterButton, SemiOvalButton, TextInput } from "../../atoms";
import styles from "./LoginContainer.module.scss";
import { useLoginForm } from "../../hooks";
import { ErrorMessage, Formik } from "formik";

export const LoginContainer = (props: { backLink?: string }) => {
    const {
        onSubmit,
        validationSchema,
        initialValues
    } = useLoginForm(props.backLink);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {({ handleChange, handleSubmit, handleBlur, values }) => (
                <form
                    onSubmit={handleSubmit}
                    className={styles.login}
                >
                    <div className={styles.loginContainer}>
                        <div className={styles.controlWrapper}>
                            <TextInput
                                text={"Email"}
                                type="text"
                                name="email"
                                value={values.email}
                                onChange={handleChange("email")}
                                onBlur={handleBlur}
                            />
                            <div className={styles.erroMessage}>
                                <ErrorMessage name="email" className={styles.erroMessage}/>
                            </div>
                        </div>

                        <div className={styles.controlWrapper}>
                            <TextInput
                                text={"Password"}
                                type="password"
                                name="password"
                                value={values.password}
                                onChange={handleChange("password")}
                                onBlur={handleBlur}
                            />
                            <div className={styles.erroMessage}>
                                <ErrorMessage name="password"/>
                            </div>
                        </div>

                        <br className={styles.indent}></br>
                        <div className={styles.loginContainerButtons}>
                            <RegisterButton backLink={props.backLink}/>
                            {/*TODO add a error message when login fails*/}
                            <SemiOvalButton
                                text={"Login"}
                                theme={"dark"}
                                type={"right"}
                                size={"middle"}
                            />
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    );
};

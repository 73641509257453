import { Header } from "../../organisms";
import { ConfirmEmailContainer } from "components/organisms/ConfirmEmailContainer";


export const ConfirmEmailTemplate = (props: {
    id: string,
    backLink?: string,
    code?: string
}) => {
    const {id, backLink, code} = props;

    return (
        <>
            <Header/>
            <ConfirmEmailContainer id={id} backLink={backLink} code={code}/>
        </>
    );
};

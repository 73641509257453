import {
    Header,
} from "../../organisms";
import { ProfileContainer } from "components/organisms/ProfileContainer";

export const ProfileTemplate = () => {
    return (
        <>
            <Header />
            <ProfileContainer />
        </>
    );
};

import { CardRecipeDescription, Divider, SemiOvalButton } from "../../atoms";
import { RecipeIngredientList } from "../RecipeIngridientList";
import styles from "./CookingRecipe.module.scss";
import { YoutubeEmbed } from "components/organisms/OnboardingContainer/YoutubeEmbed";
import { GetBasicRecipesDto } from "libs/store/recipeApi";

export type TCookingRecipe = {
  recipe: GetBasicRecipesDto;
};
export const CookingRecipe = (props: TCookingRecipe) => {
  const { name: titleText, youtubeVideoId } = props.recipe;

  return (
    <div className={styles.cardRecipeWithVideoWrapper}>
      <div className={styles.cardRecipeWithVideoContent}>
        <div className={styles.cardRecipeWithVideoTitle}>
          <p className={styles.cardRecipeWithVideoText}>{titleText}</p>
          <div className={styles.cardRecipeDividerContainer}>
            <Divider />
          </div>
        </div>
        <div className={styles.cardRecipe}>
          <div className={styles.cardRecipeIngridients}>
            <p className={styles.cardRecipeTitle}>Ingridients</p>
            <RecipeIngredientList text={props.recipe.ingredients} />
          </div>
          <div className={styles.cardRecipeDescription}>
            <p className={styles.cardRecipeTitle}>Recipe Description</p>
            <CardRecipeDescription />
          </div>
        </div>
        <div className={styles.cardVideoWrapper}>
          <div className={styles.cardVideo}>
            <YoutubeEmbed videoId={youtubeVideoId} />
          </div>
        </div>
        <div className={styles.cardRecipeWithVideoActionButton}>
          <SemiOvalButton text={"Buy Recipe"} theme="light" />
        </div>
      </div>
    </div>
  );
};

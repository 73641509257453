import { apiLogin, useAppDispatch } from "../../libs";
import * as Yup from "yup";
import { PostAuthConfirmEmailApiArg, usePostAuthConfirmEmailMutation, } from "libs/store/recipeApi";
import { useNavigate } from "react-router-dom";

export const useConfirmEmailForm = (id: string, backLink?: string) => {
    const validationSchema = Yup.object({
        confirm_code: Yup.string()
            .matches(/^[A-Z2-7]{6}$/, 'Field must be 6 characters in base32')
            .uppercase('Field must be in uppercase')
            .required('Field is required')
    });

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [addConfirmCode] = usePostAuthConfirmEmailMutation();


    const onSubmitCode = async (data: { confirm_code: string }) => {
        try {
            const payload: PostAuthConfirmEmailApiArg = {
                confirmEmailPayloadDto: {
                    id: id,
                    confirm_code: data.confirm_code
                },
            };
            console.log("payload", payload)
            const result = await addConfirmCode(payload).unwrap();
            dispatch(apiLogin({
                email: result.email,
                access_token: result.access_token,
                refresh_token: result.refresh_token,
                controlled_culinary_influencer_id: result.controlled_culinary_influencer_id || null
            }));

            navigate(backLink ? backLink : "/");

        } catch (error) {
            console.error(error);
        }
    };

    return {
        onSubmitCode,
        validationSchema,
    };
};

import React, { useCallback, useEffect, useState } from 'react';
import { DragDrop } from '@uppy/react';
import { useImageUpload } from './useImageUpload';
import styles from './ImageUploader.module.scss';
import Cropper from 'react-easy-crop';
import Modal from 'react-modal';
import { getUrl } from "libs/utils";
import { CropDto, usePostFilesAmendCropInfoByIdMutation } from "libs/store/recipeApi";


type ImageUploaderProps = {
    // onCropChange: (crp: any) => void;
    // onZoomChange: (zm: any) => void;
    userSelectedCropInfo?: (zm: CropDto) => void;
    onUploadSuccess: (fileId: string) => void;
    initialPreviewFileName?: string
};

const PreviewImage = React.memo(({
                                     thumbnail,
                                     initialPreviewFileName
                                 }: { thumbnail: { file: any, preview: string }, initialPreviewFileName: string | undefined }) => {
    if (thumbnail) return (
        <div>
            <img
                src={thumbnail.preview}
                alt={thumbnail.file.name}
                className={styles.thumbnailImage}
            />
        </div>
    )
    if (initialPreviewFileName) {
        const imgPath = getUrl({raw_token_image_filename: initialPreviewFileName});
        // console.log(imgPath)
        return <div>
            <img
                src={imgPath}
                alt={"recipe image"}
                className={styles.thumbnailImage}
            />
        </div>
    }
    return <></>
});


export const ImageUploader: React.FC<ImageUploaderProps> = ({
                                                                onUploadSuccess,
                                                                initialPreviewFileName,
                                                                userSelectedCropInfo
                                                            }) => {
    const [uploadProgress, setUploadProgress] = useState(0);
    const [fileId, setFileId] = useState<string>();
    const [updateCrop] = usePostFilesAmendCropInfoByIdMutation();
    const onUploadSuccessCallback = useCallback((fileId: string) => onUploadSuccess(fileId), [onUploadSuccess]);

    const {uppy, thumbnail} = useImageUpload((fileId, progress) => {
        console.log("fileId", fileId, "progress", progress);

        if (fileId) {
            onUploadSuccessCallback(fileId);
            setFileId(fileId);

        }
        if (progress !== undefined) {
            setUploadProgress(progress);
        }
    });

    const iconClass = thumbnail ? '' : styles.upwardsArrow;

    const [crop, setCrop] = useState({x: 0, y: 0});
    const [zoom, setZoom] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (thumbnail) {
            setIsModalOpen(true);
        }
    }, [thumbnail]);

    const closeModal = () => {
        const cropDto: CropDto = {zoom: zoom, cropX: crop.x, cropY: crop.y};
        if (!fileId) {
            throw Error("illegal state, we must have a fileId")
        }
        updateCrop({id: fileId, cropDto});
        if (userSelectedCropInfo) {
            userSelectedCropInfo(cropDto);
        }
        setIsModalOpen(false);
    };

    const onCropChange = (newCrop: { x: number; y: number }) => {
        setCrop(newCrop);
        // onCropInfosChanged({zoom: zoom, cropX: newCrop.x, cropY: newCrop.y})
    };

    const onZoomChange = (newZoom: number) => {
        setZoom(newZoom);
        // onCropInfosChanged({zoom: newZoom, cropX: crop.x, cropY: crop.y})
    };
    // const [aspect, setAspect] = useState(1);


/*
    const onCropComplete = (_: any, croppedAreaPixels: any) => {
        console.log(croppedAreaPixels.width / croppedAreaPixels.height);
    };
*/


    return (
        <div>
            <div className={styles.uploadBox}>
                <div className={styles.uploadIcon + ' ' + iconClass}></div>
                {uploadProgress < 1 && (
                    <div
                        className={styles.uploadProgress}
                        style={{width: `${uploadProgress * 100}%`}}
                    ></div>
                )}
                {uppy && <DragDrop uppy={uppy} className={styles.uppyDragDropCustom}/>}
                {thumbnail && <PreviewImage thumbnail={thumbnail} initialPreviewFileName={initialPreviewFileName}/>}
            </div>
            <Modal isOpen={isModalOpen} onRequestClose={closeModal} style={{
                // overlay: {
                //     zIndex: 9999,
                //     background: `url("/images/overlay.svg")`,
                // },
                content: {
                    padding: 0,
                },
            }}>
				<div className={styles.thumbnailContainer}>
				{thumbnail && (
                    <div key={thumbnail.file.id} className={styles.thumbnailWrapper}>
                        <div style={{width: "300px", height: "300px", background: "red"}}>
                            <Cropper
                                showGrid={false}
                                image={thumbnail.preview}
                                crop={crop}
                                zoom={zoom}
								zoomWithScroll={true}
								// style={{
								// 	cropAreaStyle: {
								// 		visibility: "hidden",
								// 	},
								// 	containerStyle: {
								// 		backgroundColor: "transparent",
								// 		opacity: 1,
								// 	},
								// }}
								// cropShape={"round"}
								disableAutomaticStylesInjection={true}
                                aspect={1}
                                onCropChange={onCropChange}
                                onZoomChange={onZoomChange}
                            />
                        </div>
						<div className={styles.tempImageLayer}></div>

                        {/* <div className={styles.overlaySvg}>
                            <img src="/images/overlay.svg" alt="overlay"/>
                        </div> */}
                        <input
                            type="range"
                            min={0.1}
                            max={3}
                            step={0.1}
                            value={zoom}
                            onChange={(e) => onZoomChange(Number(e.target.value))}
                        />
                    </div>
                )}

                    <button disabled={!fileId}
                            onClick={closeModal}>{fileId ? "Confirm" : "Uploading…"}</button>
                </div>
            </Modal>
        </div>
    );
};
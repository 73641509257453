import Logo from "./overlay.svg"
import styles from "./TokenGfx.module.scss"
import { getUrl } from "libs";

export function TokenGfx(props: { recipe: { raw_token_image_filename:string} }) {
    const tokenImageUrl = getUrl(props.recipe)
    const backgroundImageStyle = { backgroundImage: `url(${Logo}), url(${tokenImageUrl})` };

    return (
        <div className={styles.TokenGfx} style={backgroundImageStyle}/>
    );
}

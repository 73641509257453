import { LoginTemplate } from "components/templates";
import { useLocation } from "react-router-dom";

export default function Login() {
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const backLink = queryParams.has("backLink")
        ? decodeURIComponent(queryParams.get("backLink") || "")
        : undefined;

    return <LoginTemplate backLink={backLink}/>;

}

import classNames from "classnames";
import styles from "./Title.module.scss";

export type TTitle = {
	text: string;
	type: "bold" | "normal";
};

export const Title = (props: TTitle) => {
    const { text, type } = props;

    return (
        <div className={styles.titleContainer}>
            <h3
                className={classNames(
                    styles.titleContainerText,
                    styles[type]
                )}
            >
                {text}
            </h3>
        </div>
    );
};

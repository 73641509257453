import { OnboardingTemplate } from "components/templates/OnboardingTemplate";
import { useLayoutEffect } from "react";
import { useRequiredParams } from "libs/hooks/useRequiredParams";

export default function ExclusiveAccess() {
    const {id, inviteCode} = useRequiredParams(["id", "inviteCode"]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <OnboardingTemplate
                id={id as string}
                inviteCode={inviteCode as string}
            />
        </>
    );
}

import { Header, RecipesList } from "../../organisms";
import { useGetRecipesQuery } from "libs/store/recipeApi";
import { DefaultOnboardingText } from "components/molecules/OnBoardingText/DefaultOnboardingText";

export const HomeTemplate = () => {
    const {data} = useGetRecipesQuery();

    return (
        <>
            <Header/>
            <DefaultOnboardingText/>
            {data && <RecipesList data={data}/>}
        </>
    );
};

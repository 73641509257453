import { Divider, SemiOvalButton } from "../../atoms";
import styles from "./PersonalTipp.module.scss";

export type TPersonalTipp = {
  titleText: string;
  subTitleText: string;
  imageUrl: string;
};
export const PersonalTipp = (props: TPersonalTipp) => {
  const { titleText, subTitleText, imageUrl } = props;

  return (
    <div className={styles.cardPersonalTippWrapper}>
      <div className={styles.cardPersonalTippHeader}>
        <p className={styles.cardPersonalTippTitle}>{titleText}</p>
        <span className={styles.cardPersonalTippDivider}>
          <Divider />
        </span>
      </div>
      <div className={styles.cardPersonalTippContent}>
        <div className={styles.cardPersonalTippWrapper}>
          <img
            className={styles.cardPersonalTipp}
            src={imageUrl}
            alt={"secret tip"}
          />
          <div className={styles.cardPersonalTippSubTitle}>{subTitleText}</div>
        </div>
      </div>
      <div className={styles.cardPersonalTippButtonWrapper}>
        <SemiOvalButton text="Buy Recipe" theme="light" />
      </div>
    </div>
  );
};

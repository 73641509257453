import classNames from "classnames";
import styles from "./TextInput.module.scss";

export type TTextInput = {
    text: string;
    type: "text" | "email" | "password" | "textarea";
    name: string;
    value?: string;
    onChange?: any;
    onBlur?: any;
    key?: number;
    disabled?: boolean;
};

export const TextInput = (props: TTextInput) => {
    const {text, type, value, onChange, onBlur, name, key, disabled} = props;

    return type === "textarea" ? (
        <textarea
            className={classNames(styles.textInput, styles.textarea)}
            placeholder={text}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            disabled={disabled}
        />
    ) : (
        <input
            className={styles.textInput}
            placeholder={text}
            type={type}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            key={key}
            disabled={disabled}
        />
    );
};

import { apiLogin, useAppDispatch } from "libs";
import * as Yup from "yup";
import { PostAuthRegisterApiArg, RegisterResultDto, usePostAuthRegisterMutation, } from "libs/store/recipeApi";
import { useNavigate } from "react-router-dom";

export const useRegisterForm = (
    backLink: string | undefined,
    onboardingData: { id: string; inviteCode: string } | undefined
) => {
    const validationSchema = Yup.object({
        email: Yup.string()
            .trim()
            .min(2, "Too Short!")
            .max(256, "Too Long!")
            .email("Incorrect email")
            .required("Required"),
        name: Yup.string()
            .trim()
            .min(2, "Too Short!")
            .max(256, "Too Long!")
            .required("Required"),
        password: Yup.string()
            .trim()
            .min(5, "Too Short!")
            .max(50, "Too Long!")
            .required("Required"),
        confirm_password: Yup.string()
            .trim()
            .min(5, "Too Short!")
            .max(50, "Too Long!")
            .oneOf([Yup.ref("password")], "Passwords do not match.")
            .required("Required"),
    });

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [addNewUserRequest] = usePostAuthRegisterMutation();

    const initialValues = {
        email: "",
        name: "",
        password: "",
    };

    type TSubmitting = typeof initialValues & {
        onboardingData?: {
            id: string;
            inviteCode: string;
        };
    };

    const onRegister = async (data: TSubmitting) => {
        try {
            const payload: PostAuthRegisterApiArg = {
                registerPayloadDto: {
                    email: data.email,
                    name: data.name,
                    password: data.password,
                    creatorOnboardingInfo: onboardingData,
                    //todo add profile image
                },
            };
            const result: RegisterResultDto = await addNewUserRequest(
                payload
            ).unwrap();
            if (result.needs_confirm_code) {
                const backLinkFragment = backLink ? `?backLink=${encodeURIComponent(backLink)}` : "";
                navigate(`/confirm-email/${result.id}${backLinkFragment}`);
            } else {
                const qli = result.quickLoginInfo;
                if (qli) {
                    dispatch(apiLogin({
                        email: qli.email,
                        access_token: qli.access_token,
                        refresh_token: qli.refresh_token,
                        controlled_culinary_influencer_id: qli.controlled_culinary_influencer_id || null
                    }));
                    navigate(backLink ?? `/`)
                }
            }
        } catch (error) {
            console.error(error);
            throw new Error(
                `unable to register ${
                    error instanceof Error ? error.message : ""
                }`
            );
        }
    };

    return {
        initialValues,
        onRegister,
        validationSchema,
    };
};

import { Provider } from "react-redux";
// import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
// import { WagmiConfig } from "wagmi";
import { persistor, store } from "./libs";
import { PersistGate } from "redux-persist/integration/react";
import "styles/main.scss";
import * as React from "react";
// import { wagmiChains, wagmiClient } from "./contexts";
import { Helmet } from "react-helmet";
import Modal from 'react-modal';

function App(props: { children: React.ReactElement | null }) {

    const appElement = document.getElementById('root');

    if (appElement) {
        Modal.setAppElement(appElement);
    }

    return (
        <>
            <Helmet>
                <html data-theme="dark"/>
                <meta name="description" content="rare.recipes"/>
            </Helmet>
          {/*  <WagmiConfig client={wagmiClient}>
                <RainbowKitProvider chains={wagmiChains}>*/}
                    <Provider store={store}>
                        <PersistGate
                            loading={null}
                            persistor={persistor}
                        >{props.children}
                        </PersistGate>
                    </Provider>
     {/*           </RainbowKitProvider>
            </WagmiConfig>*/}
        </>
    );
}

export default App;

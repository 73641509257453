import { Header, RegisterContainer } from "../../organisms";
import { OnboardingContainer } from "components/organisms/OnboardingContainer/OnboardingContainer";
import { useState } from "react";

export const OnboardingTemplate = (props: {
  id: string;
  inviteCode: string;
}) => {
  const { id, inviteCode } = props;
  const [isRegister, setRegister] = useState(false);

  const onboardingData = { id, inviteCode };
  return (
    <>
      <Header />
      {isRegister ? (
        <RegisterContainer onboardingData={onboardingData} />
      ) : (
        <OnboardingContainer
          onboardingData={onboardingData}
          registerAction={() => {
            setRegister(true);
          }}
        />
      )}
    </>
  );
};

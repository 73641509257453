import classNames from "classnames";
import styles from "./SemiOvalButton.module.scss";

export type TSemiOvalButton = {
    text: string;
    theme?: "light" | "dark";
    type?: "left" | "right";
    size?: "big" | "middle" | "small";
    margin?: boolean;
    disabled?: boolean;
    onClick?: () => void;
    noSubmit?: boolean;
};

export const SemiOvalButton = (props: TSemiOvalButton) => {
    const {text, theme, type, size, margin, disabled, onClick, noSubmit} = props;

    const enabledTheme = disabled ? theme + "-disabled" : theme;
    return (
        <button onClick={onClick}
                disabled={disabled}
                className={classNames(
                    styles.semiOvalButton,
                    styles[enabledTheme || "dark"],
                    styles[type || "right"],
                    styles[size || "middle"],
                    margin && styles.margin,
                    onClick && styles.clickable
                )}
                type={noSubmit ? "button" : "submit"}
        >
            {text}
        </button>
    );
};

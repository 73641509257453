const envUrl = import.meta.env.VITE_PUBLIC_API_URL_RUNTIME as string;

const Environment = ["local", "test", "prod"] as const;
type TEnvironment = typeof Environment[number];

export function getUrlConfig(): { frontendBaseUrl: string; publicApiUri: string, env: TEnvironment } {
    let frontendBaseUrl = "http://localhost:3000";
    let env: TEnvironment = "local";
    if (typeof window !== 'undefined') {
        const url = new URL(window.location.href);
        frontendBaseUrl = `${url.protocol}//${url.hostname}`;
        if (url.port && url.port !== '80' && url.port !== '443') {
            frontendBaseUrl += `:${url.port}`;
        }
        if (["test.rare.recipes", "rarerecipes.customer.capacity.at"].includes(url.hostname)) {
            env = "test";
        } else if (["rare.recipes", "www.rare.recipes"].includes(url.hostname)) {
            env = "prod";
        } else {
            env = "local"
        }
    }

    return {publicApiUri: envUrl, frontendBaseUrl, env: env};
}

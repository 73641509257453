import { RecipeTemplate } from "../../components/templates";
import { useRequiredParams } from "libs/hooks/useRequiredParams";

export default function RecipeDetails() {
    const {id} = useRequiredParams(["id"]);

    return (
        <>
            <RecipeTemplate id={id}/>
        </>
    );
}

import { GreenButton } from "components/atoms/GreenButton";
import { apiLogout, selectAuth, useAppDispatch, useAppSelector } from "libs";
import { Link } from "react-router-dom";

export function LoginButton() {
    const auth = useAppSelector(selectAuth);
    const dispatch = useAppDispatch();

    /*TODO add profile picture and link to profile*/

    return auth?.email
        ? <div>
            <Link to={"/profile"}>
                <div>{auth?.email}</div>
            </Link>
            <a onClick={() => {
                dispatch(apiLogout());
            }}>Logout</a>
        </div>
        : <Link to="/login">
            <GreenButton text="Login"/>
        </Link>;
}

import * as Yup from "yup";
import {
    CreateRecipeDto,
    UpdateRecipeDto,
    useGetRecipesByByIdQuery,
    usePostRecipesUpdateMutation,
} from "libs/store/recipeApi";
import { useFormik } from "formik";
import { useEffect, useState } from "react";

function fileNameToUuid(queriedData: { raw_token_image_filename: string }) {
    const idx = queriedData.raw_token_image_filename.lastIndexOf(".");
    return queriedData.raw_token_image_filename.substring(0, idx);
}

export const useEditRecipeForm = (id: string) => {

    const validationSchema = Yup.object({
        name: Yup.string()
            .trim()
            .min(2, "Too Short!")
            .max(256, "Too Long!")
            .required("Required"),
        youtubeLink: Yup.string(),
        ingredients: Yup.string(),
        instructions: Yup.string(),
        secret_hint: Yup.string(),
        releaseStatus: Yup.string()
            .oneOf(["Draft", "Release"], "Invalid release status")
            .required("Required")
    });

    const {data: queriedData, refetch} = useGetRecipesByByIdQuery({id: id}, {skip: !id});
    const [status, setStatus] = useState<string>();
    const [updateRecipeApi] = usePostRecipesUpdateMutation();

    // we use create instead of update here, since the id is provided and not editable
    const initialValues: CreateRecipeDto = {
        name: "",
        youtubeVideoId: "",
        ingredients: "",
        instructions: "",
        secret_hint: "",
        raw_token_image_id: "",
        releaseStatus: "Draft"
    };

    const onSubmit = async (data: CreateRecipeDto) => {
        try {

            const payload: UpdateRecipeDto = {
                ...data,
                id: id
            };
            console.log("saving payload", payload)
            await updateRecipeApi({updateRecipeDto: payload}).unwrap();
            setStatus("recipe successfully updated")
        } catch (error) {
            const msg = `unable to update ${(error instanceof Error ? error.message : '')}`;
            setStatus(msg);
            console.error(msg);
            throw error;
        }
    };

    const formik = useFormik({
        onSubmit: onSubmit,
        initialValues: initialValues,
        validationSchema: validationSchema,
    })
    const {setFieldValue} = formik;


    useEffect(() => {
        function updateValue(fieldName: string, val: any) {
            if (val) {
                setFieldValue(fieldName, val);
            }
        }

        if (queriedData) {
            updateValue("ingredients", queriedData.ingredients);
            updateValue("instructions", queriedData.instructions);
            updateValue("releaseStatus", queriedData.releaseStatus);
            updateValue("name", queriedData.name);
            updateValue("secret_hint", queriedData.secret_hint);
            updateValue("youtubeVideoId", queriedData.youtubeVideoId);
            updateValue("raw_token_image_id", fileNameToUuid(queriedData));

        }
    }, [queriedData, setFieldValue])

    return {
        queriedData,
        formik,
        status,
        refetch
    };
};

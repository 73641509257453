import { FavoriteButton, SemiOvalButton } from "../../atoms";
import styles from "./RecipeCard.module.scss";
import { GetPublicCulinaryInfluencerInfoDto } from "libs/store/recipeApi";
import { getUrl } from "libs";
import { ProfileButton } from "components/atoms/ProfileButton";
import { ChefSignature } from "components/molecules/ChefSignature/ChefSignature";
import { Link } from "react-router-dom";

export type TRecipeItem = {
  id: string;
  name: string;
  raw_token_image_filename: string;
  releaseStatus: "Draft" | "Released" | "SoldOut";
  disableButton?: boolean;
};
export type TRecipeCard = {
  recipe: TRecipeItem;
  creator: GetPublicCulinaryInfluencerInfoDto;
  disableButton?: boolean;
};
export const RecipeCard = (props: TRecipeCard) => {
  const { recipe, creator, disableButton } = props;
  const tokenImageUrl = getUrl(recipe);
  //const tokenImageUrl =
  ("https://i2.wp.com/seonkyounglongest.com/wp-content/uploads/2016/05/IMG_8375-copy.jpg?fit=1300%2C730&ssl=1");

  return (
    <div>
      <div
        className={styles.RecipeCard}
        style={{
          background: `url(${tokenImageUrl}) no-repeat center center`,
          backgroundSize: "cover",
        }}
      >
        <div className={styles.recipeCardHeader}>
          <div className={styles.favButton}>
            <FavoriteButton recipe={recipe}/>
          </div>

          <div className={styles.profilePicButton}>
            <ProfileButton profile={creator} />
          </div>

          <div className={styles.statusText}>
            {recipe.releaseStatus !== "Released" ? recipe.releaseStatus : ""}
          </div>
        </div>

        <div className={styles.recipeCardFooter}>
          <div className={styles.recipeCardFooterTextBlock}>
            <p className={styles.recipeCardFooterText}>
              {/*Salmon Sesame Noodle Kimbap*/}
              {recipe.name}
            </p>
            <p className={styles.recipeCardFooterName}>
              {/*by 냥숲nyangsoop*/}
              by {creator.name}
            </p>
          </div>

          <div className={styles.recipeCardButtonBlock}>
            <p className={styles.recipeCardButtonText}>2/10 available</p>
            <div className={styles.recipeCardButtonContainer}>
              <Link to={`/recipe/${recipe.id}`} style={{cursor: "pointer !important"}}>
                {disableButton ? null : <SemiOvalButton text={"View Recipe"}/>}
              </Link>
            </div>
          </div>
          <ChefSignature creator={creator} />
        </div>
      </div>
    </div>
  );
};

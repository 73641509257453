import { ReactNode } from "react";
import { motion } from "framer-motion";

type TCommonLayout = {
    children: ReactNode;
};

const ANIMATION = {
    INITIAL: { x: 300, opacity: 0 },
    ANIMATE: { x: 0, opacity: 1 },
    EXIT: { x: -300, opacity: 0 },
    TRANSITION: {
        type: "spring",
        stiffness: 150,
        damping: 20,
    },
};

export const CommonLayout = (props: TCommonLayout): JSX.Element => {
    const { children } = props;

    return (
        <motion.div
            initial={ANIMATION.INITIAL}
            animate={ANIMATION.ANIMATE}
            exit={ANIMATION.EXIT}
            transition={ANIMATION.TRANSITION}
        >
            {children}
        </motion.div>
    );
};

import { ConfirmedIcon, SemiOvalButton } from "components/atoms";
import styles from "./FollowDivider.module.scss";
import { ProfileButton } from "components/atoms/ProfileButton";

export type TFollowDivider = {
    author: string;
};
export const FollowDivider = (props: TFollowDivider) => {
    const { author } = props;

    return (
        <div className={styles.cardFollowDividerWrapper}>
            <div className={styles.cardFollowDividerUser}>
                <div className={styles.cardFollowDividerProfile}>
                    <div className={styles.cardFollowDividerPhoto}>
                        <ProfileButton
                            profile={{
                                name: "",
                                id: "",
                                profilePictureCIUrl: "",
                            }}
                        />
                    </div>
                    <p className={styles.cardFollowDividerText}>
                        <span className={styles.cardFollowDividerName}>
                            {author}
                            <ConfirmedIcon />
                        </span>

                        <div className={styles.cardFollowDividerButton}>
                            <SemiOvalButton text="Follow" theme="light" />
                        </div>
                    </p>
                </div>
            </div>
        </div>
    );
};

import { TokenGfx } from "components/atoms/TokenGfx/TokenGfx";
import styles from "components/organisms/BuyRecipe/BuyRecipel.module.scss";
import { Divider } from "components/atoms/Divider/Divider";
import { useEditRecipeForm } from "components/hooks/useEditRecipeForm";
import { SemiOvalButton, TextInput } from "components/atoms";
import { ImageUploader } from "components/organisms/ImageUploader/ImageUploader";


export const EditRecipe = ({dbId}: { dbId: string }) => {

    const {queriedData: recipeData, formik, refetch} = useEditRecipeForm(dbId);

    const creatorLabel = recipeData?.creator?.name && <>by {recipeData.creator.name}</>;
    const {setFieldValue, submitForm, values} = formik;

    async function setToDraft() {
        setFieldValue("releaseStatus", "Draft");
        await submitForm();
        refetch(); //TODO avoid
    }

    async function setToRelease() {
        if (values.releaseStatus === "Draft") {
            setFieldValue("releaseStatus", "Released");
        }
        await submitForm();
        //TODO we could avoid refetching explicitly, we know everything from the result.
        // this is just done to hide the "save as draft" button
        refetch();
    }

    const isDraft = recipeData?.releaseStatus === "Draft";
    const saveLabel = isDraft ? "Publish" : "Update";
    return (
        <>
            <div className={styles.RecipeDetail}>
                {recipeData && <>
                    <div>{recipeData.name} {creatorLabel}</div>
                    <Divider/>
                    <TokenGfx recipe={recipeData}/>
                    <form onSubmit={formik.handleSubmit}>
                        <TextInput
                            text="Name"
                            type="text"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <TextInput
                            text="YouTube ID"
                            type="text"
                            name="youtubeVideoId"
                            value={formik.values.youtubeVideoId}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <TextInput
                            text="Ingredients"
                            type="textarea"
                            name="ingredients"
                            value={formik.values.ingredients}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <TextInput
                            text="Instructions"
                            type="textarea"
                            name="instructions"
                            value={formik.values.instructions}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <TextInput
                            text="Secret Hint"
                            type="text"
                            name="secret_hint"
                            value={formik.values.secret_hint}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <ImageUploader
                          /*  onCropChange ={(zoom, panX, panY)={

                            }              }       */
                            onUploadSuccess={(fileId) => {
                                console.log("fresh raw_token_image_id", fileId)
                                setFieldValue("raw_token_image_id", fileId)
                            }}
                            initialPreviewFileName={recipeData.raw_token_image_filename}
                        />
                        {isDraft &&
                            <SemiOvalButton noSubmit text={"Save as Draft"} onClick={setToDraft}/>}
                        <SemiOvalButton noSubmit text={saveLabel} onClick={setToRelease}/>
                    </form>
                </>}
            </div>
        </>
    );
};

import React from "react";
import styles from "./Select.module.scss";

export type TSelect = {
    options: string[];
    name: string;
    value: string;
    onChange: any;
    onBlur?: any;
};

export const Select: React.FC<TSelect> = ({ options, name, value, onChange, onBlur }) => {
    return (
        <select
            className={styles.select}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
        >
            {options.map((option, index) => (
                <option key={index} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
};

import styles from "./RecipeIngridientList.module.scss";

export type TRecipeIngridientList = { text: string };

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const RecipeIngredientList = (props: TRecipeIngridientList) => {
  console.log(props);
  return (
    <div className={styles.recipeIngridientListWrapper}>
      {mockData?.map((value) => (
        <div className={styles.recipeIngridientListWrapper} key={value.id}>
          <div className={styles.recipeIngridientCount}>{value.count}</div>
          <div className={styles.recipeIngridientName}>{value.name}</div>
        </div>
      ))}
    </div>
  );
};

const mockData = [
  {
    id: 1,
    count: "1/4 stuck",
    name: "tahini",
  },
  { id: 2, count: "2 tbsp", name: "plant-based mayonnaise" },
  { id: 3, count: "1 tbsp", name: "soy sauce" },
  { id: 4, count: "1 tbsp", name: "rice vinegar" },
  { id: 5, count: "1 tbsp", name: "agave necrat" },
  { id: 6, count: "1 tbs", name: "yondu or vegetarian oyster sauce" },
  { id: 7, count: "2 tbsp", name: "sesame oil" },
  { id: 8, count: "1 - 4 tbsp", name: "Seonkyoung's chill oil" },
  { id: 9, count: "2 - 4 tbsp", name: "water" },
  { id: 10, count: "1/4 stuck", name: "tahini" },
  { id: 11, count: "1 tsp", name: "minced garlic" },
  { id: 12, count: "1 tsp", name: "finely grated ginger" },
  { id: 13, count: "3 portions", name: "of dried or fresh weath noodles" },
  { id: 14, count: "2 pieces", name: "Persian cucumber, julienned" },
  { id: 15, count: "12 tsp", name: "ice (nach geschmack)" },
  { id: 16, count: "1 tbsp", name: "agave necrat" },
  { id: 17, count: "1 tbs", name: "yondu or vegetarian oyster sauce" },
  { id: 18, count: "2 tbsp", name: "sesame oil" },
];

import styles from "./BlackDivider.module.scss";
import { ArrowIcon } from "../ArrowIcon";

export type TBlackDivider = {
  text: string;
};

export const BlackDivider = (props: TBlackDivider) => {
  const { text } = props;
  return (
    <div className={styles.cardBlackDividerWrapper}>
      <p className={styles.cardBlackDividerText}>{text}</p>
      <div className={styles.cardBlackDividerContent}>
        <ArrowIcon />
      </div>
    </div>
  );
};

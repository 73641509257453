import YouTube from "react-youtube";

export const YoutubeEmbed = ({videoId}: {
    videoId: string
}) => {
    return videoId ? <YouTube opts={{host: "https://www.youtube-nocookie.com"}}
                              videoId={videoId} /*onReady={event => {
        /!*let duration = event.target.getDuration();*!/
        // console.log("duration 1", duration)
        // console.log("event", event)
    }

    }*/ /*onPause={event => {
        const {getCurrentTime, getDuration} = event.target;
        let time = getCurrentTime();
        let duration = getDuration();
        // console.log("paused at", time)
        // console.log("duration 2", duration)
        // console.log("event.target", event.target)
    }}*/ /*onPlay={event => {
    }
    }*//> : <></>

}

import { RouteProps } from 'react-router-dom';
import Home from './pages/index';
import Custom404 from './pages/404';
import Login from './pages/login';
import ExclusiveAccess from './pages/exclusiveAccess/[id]/[inviteCode]';
import RecipeDetails from './pages/recipe/[id]';
import BuyRecipe from './pages/recipe/[id]/buy';
import EditRecipe from './pages/recipe/[id]/edit';
import Custom500 from './pages/500';
import Register from './pages/register';
import DevInfos from './pages/devinfos';
import Profile from './pages/profile';
import ConfirmEmail from './pages/confirm-email/[uid]';
import AdminCreateCi from "./pages/admin/admin-create-ci";
import { getUrlConfig } from "libs/utils";
import PlanC from "./pages/PlanC/PlanC";

const prodRoutes: RouteProps[] = [
    {path: '/version', element: <DevInfos/>},
    {path: '/*', element: <PlanC/>},
];

const testRoutes: RouteProps[] = [
    {path: '/', element: <Home/>, index: true},
    {path: '/404', element: <Custom404/>},
    {path: '/login', element: <Login/>},
    {path: '/planC', element: <PlanC/>},
    {path: '/exclusiveAccess/:id/:inviteCode', element: <ExclusiveAccess/>},
    {path: '/recipe/:id', element: <RecipeDetails/>},
    {path: '/recipe/:id/buy', element: <BuyRecipe/>},
    {path: '/recipe/:id/edit', element: <EditRecipe/>},
    {path: '/500', element: <Custom500/>},
    {path: '/admin/admin-create-ci', element: <AdminCreateCi/>},
    {path: '/register', element: <Register/>},
    {path: '/version', element: <DevInfos/>},
    {path: '/devinfos', element: <DevInfos/>},
    {path: '/profile', element: <Profile/>},
    {path: '/confirm-email/:uid', element: <ConfirmEmail/>},
    {path: '/*', element: <Custom404/>, index: true},

];

const {env} = getUrlConfig();
const routes = env === "prod" ? prodRoutes : testRoutes;

export default routes;

import { SemiOvalButton } from "components/atoms/SemiOvalButton";
import { Link } from "react-router-dom";

export function RegisterButton(props: { backLink?: string }) {
    const backLinkFragment = props.backLink ? `?backLink=${encodeURIComponent(props.backLink)}` : "";
    return <Link to={`/register${backLinkFragment}`}>
        <SemiOvalButton
            noSubmit
            text={"Register"}
            theme={"light"}
            type={"left"}
            size={"middle"}
            margin
        />
    </Link>;
}

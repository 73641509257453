import { RecipeEditTemplate } from "components/templates/RecipeBuyTemplate";
import { useRequiredParams } from "libs/hooks/useRequiredParams";

export default function EditRecipe() {
    const {id} = useRequiredParams(["id"]);

    return (
        <>
            <RecipeEditTemplate id={id}/>
        </>
    );
}

import { GetBasicRecipesDto } from "libs/store/recipeApi";
import styles from "./TwoIs.module.scss";
import { IngredientsText } from "components/organisms/OnboardingContainer/IngredientsText";

export function TwoIs(props: { recipe: GetBasicRecipesDto }) {
    return <div className={styles.TwoIs}>
        <div className={styles.ingredients}>
            <div className={styles.headline}>Ingredients</div>
            <div className={styles.mainSection}>
                <IngredientsText text={props.recipe.ingredients}/>
            </div>
        </div>
        <div className={styles.instructions}>
            <div className={styles.headline}>Instructions</div>
            <div className={styles.mainSection}>
                <IngredientsText text={props.recipe.instructions}/>
            </div>
        </div>
    </div>;
}

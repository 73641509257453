import { RecipeBuyTemplate } from "components/templates/RecipeEditTemplate/RecipeBuyTemplate";
import { useLocation } from "react-router-dom";
import { useRequiredParams } from "libs/hooks/useRequiredParams";

export default function BuyRecipe() {
    const {id} = useRequiredParams(["id"]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const saleId = queryParams.get("saleId") || undefined;
    const success = queryParams.get("success") || undefined;

    return (
        <>
            <RecipeBuyTemplate id={id} saleId={saleId} success={success}/>
        </>
    );
}

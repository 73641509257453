import styles from "./OnBoardingText.module.scss";

import { SemiOvalButton } from "components/atoms";

export type TOnBoardingText = {
    title: string;
    bodyText: string;
    secondBodyTitle: string;
};

export const OnBoardingText = (props: TOnBoardingText) => {
    const { title, bodyText, secondBodyTitle } = props;
    return (
        <div className={styles.cardTextWrapper}>
            <div className={styles.circle}></div>
            <div className={styles.cardInfoText}>
                <p className={styles.cardTitle}>{title}</p>
                <div className={styles.cardBodyInfo}>
                    <div className={styles.cardMainBodyContainer}>
                        <p className={styles.cardMainBodyText}>{bodyText}</p>
                    </div>
                    <div className={styles.cardSecondBodyContainer}>
                        <p className={styles.cardSecondBodyText}>
                            {secondBodyTitle}
                        </p>
                        <div className={styles.cardButtonContainer}>
                            <SemiOvalButton text="Login" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

import { ContainerWithBorder, Title } from "../../atoms";
import { RecipesList } from "components/organisms/RecipesList";
import {
    GetRecipeDto,
    useGetRecipesMineQuery,
    useGetSocialLikeMineQuery,
    usePostRecipesDetailsMutation
} from "libs/store/recipeApi";
import { useEffect, useState } from "react";

export const ProfileContainer = () => {
    const {data: createdRecipes} = useGetRecipesMineQuery();
    const {data: socialsData} = useGetSocialLikeMineQuery();
    const [queryDetails] = usePostRecipesDetailsMutation();
    const [likedDetails, setLikedDetails] = useState<GetRecipeDto[]>();

    useEffect(() => {
        (async () => {
            if (!socialsData || !queryDetails) return;
            try {
                const result = await queryDetails({recipeIdsDto: {recipe_ids: socialsData.recipe_ids}}).unwrap();
                setLikedDetails(result);
            } catch (error) {
                console.error("Error fetching liked details:", error);
            }
        })();
    }, [queryDetails, socialsData]);

    return (
        <>
            {createdRecipes && createdRecipes.length > 0 && (
                <>
                    <Title text={"Recipes you created"} type="normal"/>
                    <ContainerWithBorder>
                        <RecipesList data={createdRecipes}/>
                    </ContainerWithBorder>
                </>
            )}

            {likedDetails && likedDetails.length > 0 && (
                <>
                    <Title text={"Recipes you liked"} type="normal"/>
                    <ContainerWithBorder>
                        <RecipesList data={likedDetails}/>
                    </ContainerWithBorder>
                </>
            )}
        </>
    );
};


export const ArrowIcon = () => {
    return (
        <>
            <svg
                width="23"
                height="30"
                viewBox="0 0 23 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1.32422 1.49561L11.2017 11.3728L21.0789 1.49561"
                    stroke="#AECC53"
                    stroke-width="2"
                    stroke-linecap="round"
                />
                <path
                    d="M1.32422 9.76514L11.2017 19.6423L21.0789 9.76514"
                    stroke="#AECC53"
                    stroke-width="2"
                    stroke-linecap="round"
                />
                <path
                    d="M1.32422 17.7832L11.2017 27.6604L21.0789 17.7832"
                    stroke="#AECC53"
                    stroke-width="2"
                    stroke-linecap="round"
                />
            </svg>
        </>
    );
};

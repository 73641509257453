import styles from "./CheckBox.module.scss";

export type TCheckBox = {
	label: string;
	id: string;
	checked: boolean;
	onChange: any;
};
export const CheckBox = (props: TCheckBox) => {
    const { label, id, checked, onChange } = props;

    return (
        <div className={styles.checkBox}>
            <input
                type="checkbox"
                id={id}
                className={styles.checkboxInput}
                checked={checked}
                onChange={onChange}
                tabIndex={0}
            />
            <label htmlFor={id}>
                <span className={styles.checkbox}></span>
            </label>
            <label className={styles.checkboxLabel} htmlFor={id}>{label}</label>
        </div>
    );
};

import { useEffect, useState } from 'react';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import { getUrlConfig } from "libs/utils";
import { selectAccessToken, useAppSelector } from "libs/store";

export const useImageUpload = (onUploadSuccess: (fileId: string|undefined, progress: number) => void) => {
    const [uppy, setUppy] = useState<Uppy|null>();
    const [thumbnail, setThumbnail] = useState<{ file: any, preview: string } | null>(null);
    const {publicApiUri} = getUrlConfig();
    const authToken = useAppSelector(selectAccessToken);

    useEffect(() => {
        console.log("creating uppy");
        const uppyInstance = new Uppy({
            autoProceed: true,
            restrictions: {
                allowedFileTypes: ['image/*'],
            },
        });

        uppyInstance.use(ThumbnailGenerator, {thumbnailWidth: 300, thumbnailHeight: 300});
        uppyInstance.on('thumbnail:generated', (file, preview) => {
            setThumbnail({file, preview});
        });

        uppyInstance.use(XHRUpload, {
            endpoint: publicApiUri + "/files/",
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });

        const handleUploadStart = () => {
            console.log("starting upload")
            setThumbnail(null);
        };

        const handleUploadProgress = (_: any, progress: any) => {
            console.log("progress...")
            // Pass the upload progress to the parent component via the onUploadSuccess callback
            onUploadSuccess(undefined, progress.bytesUploaded / progress.bytesTotal);
        };

        const handleUploadComplete = (_: any, response: any) => {
            console.log("UL complete")
            const createdIds: { id: string }[] = response.body.data;
            onUploadSuccess(createdIds[0].id, 1);
        };

        uppyInstance.on('upload', handleUploadStart);
        uppyInstance.on('upload-progress', handleUploadProgress);
        uppyInstance.on('upload-success', handleUploadComplete);

        setUppy(uppyInstance);

        return () => {
            console.log("shutting down uppy")
            uppyInstance.off('upload', handleUploadStart);
            uppyInstance.off('upload-progress', handleUploadProgress);
            uppyInstance.off('upload-success', handleUploadComplete);
            uppyInstance.close();
            setUppy(null);
        };
    }, []);

    return {uppy, thumbnail};
};

import styles from "./FavoriteButton.module.scss";
import { TRecipeItem } from "components/molecules";
import { useIsTokenBasedAuthorized } from "libs/store";
import { useGetSocialLikeMineQuery, usePostSocialLikeMutation } from "libs/store/recipeApi";
import { useEffect, useState } from "react";
import favsvg from "./favButton.svg";
import favselected from "./favSelected.svg";

export const FavoriteButton = (props: { recipe: TRecipeItem }) => {
    const isAuth = useIsTokenBasedAuthorized();
    const {data} = useGetSocialLikeMineQuery(undefined, {skip: !isAuth})
    const [doLike] = usePostSocialLikeMutation()
    const isFavourite = !!data?.recipe_ids?.find(value => value === props.recipe.id);
    const [selected, setSelected] = useState<boolean>(false)

    useEffect(() => {
        console.log("selecting:" + isFavourite)
        setSelected(isFavourite);
    }, [isFavourite])

    return (
        <div className={styles.favoriteButton} onClick={(event) => {
            if (!isAuth) return;
            doLike({addLikePayloadDto: {recipe_id: props.recipe.id, enabled: !selected}});
            setSelected(!selected);
            event.stopPropagation(); //not sure if that is doing anything..
        }}>
            <img
                className={styles.favoriteButtonImage}
                src={selected ? favselected : favsvg}
                alt={"heart icon"}
            />
        </div>
    );
};

import { Divider } from "../../atoms";
import styles from "./TopFansList.module.scss";
import { TopFansCard } from "../TopFansCard";

export type TTopFansList = {
  titleText: string;
  subTitleText: string;
  imageUrl: string;
};
export const TopFansList = (props: TTopFansList) => {
  const { titleText, subTitleText } = props;

  return (
    <div className={styles.cardTopFansWrapper}>
      <div className={styles.cardTopFansHeader}>
        <p className={styles.cardTopFansTitle}>{titleText}</p>
        <span className={styles.cardTopFansDivider}>
          <Divider />
        </span>
        <p className={styles.cardTopFansSubTitle}>{subTitleText}</p>
      </div>

      <div className={styles.cardTopFansContainer}>
        <TopFansCard fansData={mockData} />
      </div>
    </div>
  );
};

const mockData = [
  {
    id: 1,
    title: "GOURMAND GURU",
    personalNumber: 1,
    fanNumber: "001",
    photoUrl:
      "https://raw.githubusercontent.com/Ashwinvalento/cartoon-avatar/master/lib/images/female/5.png",
    commentText: "Here will be your fan’s comment!",
    commentDate: "22:21 12.12.23",
  },
  {
    id: 2,
    title: "CULINARY CONNOISSEUR",
    personalNumber: 2,
    fanNumber: "002",
    photoUrl:
      "https://raw.githubusercontent.com/Ashwinvalento/cartoon-avatar/master/lib/images/female/5.png",
    commentText: "Comment!",
    commentDate: "11:11 11.11.11",
  },
  {
    id: 3,
    title: "CULINARY CONNOISSEUR",
    personalNumber: 3,
    fanNumber: "003",
    photoUrl:
      "https://raw.githubusercontent.com/Ashwinvalento/cartoon-avatar/master/lib/images/female/5.png",
    commentText: "comment!",
    commentDate: "22:21 12.12.23",
  },
  {
    id: 4,
    title: "GOURMAND GURU",
    personalNumber: 4,
    fanNumber: "004",
    photoUrl:
      "https://raw.githubusercontent.com/Ashwinvalento/cartoon-avatar/master/lib/images/female/5.png",
    commentText: "comment!",
    commentDate: "22:21 12.12.23",
  },
  {
    id: 5,
    title: "GOURMAND GURU",
    personalNumber: 5,
    fanNumber: "005",
    photoUrl:
      "https://raw.githubusercontent.com/Ashwinvalento/cartoon-avatar/master/lib/images/female/5.png",
    commentText: "comment!",
    commentDate: "22:21 12.12.23",
  },
  {
    id: 6,
    title: "GOURMAND GURU",
    personalNumber: 6,
    fanNumber: "006",
    photoUrl:
      "https://raw.githubusercontent.com/Ashwinvalento/cartoon-avatar/master/lib/images/female/5.png",
    commentText: "comment!",
    commentDate: "22:21 12.12.23",
  },
  {
    id: 7,
    title: "GOURMAND GURU",
    personalNumber: 7,
    fanNumber: "007",
    photoUrl:
      "https://raw.githubusercontent.com/Ashwinvalento/cartoon-avatar/master/lib/images/female/5.png",
    commentText: "comment!",
    commentDate: "22:21 12.12.23",
  },
  {
    id: 8,
    title: "GOURMAND GURU",
    personalNumber: 8,
    fanNumber: "008",
    photoUrl:
      "https://raw.githubusercontent.com/Ashwinvalento/cartoon-avatar/master/lib/images/female/5.png",
    commentText: "comment!",
    commentDate: "22:21 12.12.23",
  },
  {
    id: 9,
    title: "GOURMAND GURU",
    personalNumber: 9,
    fanNumber: "009",
    photoUrl:
      "https://raw.githubusercontent.com/Ashwinvalento/cartoon-avatar/master/lib/images/female/5.png",
    commentText: "comment!",
    commentDate: "22:21 12.12.23",
  },
  {
    id: 10,
    title: "GOURMAND GURU",
    personalNumber: 10,
    fanNumber: "010",
    photoUrl:
      "https://raw.githubusercontent.com/Ashwinvalento/cartoon-avatar/master/lib/images/female/5.png",
    commentText: "comment!",
    commentDate: "22:21 12.12.23",
  },
];

import { Header } from "../../organisms";
import { EditRecipe } from "components/organisms/EditRecipe/EditRecipe";

export const RecipeEditTemplate = (props: {
    id: string;

}) => {
    const {id} = props;
    return (
        <>
            <Header/>
            <EditRecipe dbId={id}/>
        </>
    );
};

import classNames from "classnames";
import styles from "./GreenButton.module.scss";

export type TGreenButton = {
	text: string;
};

export const GreenButton = (props: TGreenButton) => {
    const { text } = props;

    return (
        <button className={classNames(styles.greenButton)}>
            {text}
        </button>
    );
};

import styles from "./Header.module.scss";
import logoHeader from "./logo_header.png";
import classNames from "classnames";
import { LoginButton, MenuButton, } from "../../atoms";
import { Link } from "react-router-dom";
import { useState } from "react";
import { MenuContainer } from "../MenuContainer";

export type THideButtons = {
    noLogin?: boolean;
    noMenu?: boolean;
};

export const Header = (props: THideButtons) => {
    const {noLogin, noMenu} = props;
    const [isOpen, setOpen] = useState(false);

    return (
        <div className={styles.headerContainter}>
            <div
                className={classNames(
                    styles.headerContainterAuth,
                    noLogin && styles.hide
                )}
            >
                {!noMenu && <MenuButton setOpen={setOpen} isOpen={isOpen}/>}
                {isOpen && <MenuContainer setOpen={setOpen} isOpen={isOpen}/>}
                {!noLogin && <LoginButton/>}
            </div>
            <div className={styles.headerContainerLogo}>
                <Link to="/">
                    <img src={logoHeader} alt="logo"/>
                </Link>
            </div>
        </div>
    );
};

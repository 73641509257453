import { useGetVersionQuery } from "libs/store/recipeApi";
import { getUrlConfig, selectAuth, useAppSelector } from "libs";
import { ImageUploader } from "components/organisms/ImageUploader/ImageUploader";
import { useState } from "react";

export default function DevInfos() {
    const publicInfos = getUrlConfig();

    const auth = useAppSelector(selectAuth);

    const {data: versionInfo} = useGetVersionQuery(undefined, {refetchOnFocus: true});
    const infos = {
        publicInfos,
        serverApiVersion: versionInfo,
        stripePubKey: import.meta.env.VITE_STRIPE_PUBKEY,
        frontendVersion: import.meta.env.VITE_APP_VERSION || "testing",
        auth
    };

	const [crop, setCrop] = useState({x: 0, y: 0});
    const [zoom, setZoom] = useState(1);
	const [fileId, setFileId] = useState<string>();

	console.log("crop", crop, "zoom", zoom);

	const {publicApiUri} = getUrlConfig();

    const tokenImage = fileId && publicApiUri + "/uploads/" + fileId + ".png";

    return (
        <>

            <ImageUploader
                userSelectedCropInfo={infos => {
                    setCrop({x: infos.cropX || 0, y: infos.cropY || 0});
                    setZoom(infos.zoom || 1);
                }}
                onUploadSuccess={(fileid) => {
                    setFileId(fileid)
                    console.log("fileid", fileid)
                }}
            />
            <div style={{
                backgroundImage: `url(${tokenImage})`,
                backgroundSize: `${zoom * 100}%`,
                backgroundPosition: `-${crop.x}px -${crop.y}px`,
                backgroundRepeat: "no-repeat",
                width: "500px",
                height: "500px",
            }}></div>
            <div style={{
                backgroundImage: `url(/images/overlay.svg), url(${tokenImage})`,
                backgroundSize: `initial, ${zoom * 100}%`,
                backgroundPosition: `initial, -${crop.x}px -${crop.y}px`,
                // backgroundRepeat: "no-repeat, no-repeat",
                width: "538px",
                height: "538px",
            }}></div>
            <pre>{JSON.stringify(infos, undefined, " ")}</pre>
        </>
    );
}

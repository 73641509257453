import { SemiOvalButton, TextInput } from "../../atoms";
import styles from "./ConfirmEmailContainer.module.scss";
import { ErrorMessage, Formik } from "formik";
import { useConfirmEmailForm } from "components/hooks/useConfirmEmailForm";
import { useEffect } from "react";

export const ConfirmEmailContainer = (props: {
    id: string,
    backLink?: string,
    code?: string,
}) => {
    const {id, backLink, code} = props;

    const {
        validationSchema,
        onSubmitCode
    } = useConfirmEmailForm(id, backLink);

    console.log("code2", code)

    return (
        <Formik
            initialValues={{confirm_code: ""}}
            onSubmit={onSubmitCode}
            validationSchema={validationSchema}
        >
            {({/*handleChange,*/ handleSubmit, handleBlur, values, setFieldValue}) => {
                const handleUppercaseChange = (fieldName: string) => (e: any) => {
                    const uppercaseValue = e.target.value.toUpperCase();
                    setFieldValue(fieldName, uppercaseValue);
                };

                // eslint-disable-next-line react-hooks/rules-of-hooks
                useEffect(() => {
                    if (code) {
                        setFieldValue("confirm_code", code);
                    }
                }, [code]);

                return (
                    <form
                        onSubmit={handleSubmit}
                        className={styles.login}
                    >
                        <div className={styles.loginContainer}>
                            <div className={styles.controlWrapper}>
                                <TextInput
                                    text={"Confirm Code"}
                                    type="text"
                                    name="confirm_code"
                                    value={values.confirm_code}
                                    onChange={handleUppercaseChange("confirm_code")}
                                    onBlur={handleBlur}
                                />
                                <div className={styles.errorMessage}>
                                    <ErrorMessage name="email" className={styles.errorMessage}/>
                                </div>
                            </div>
                            <div className={styles.loginContainerButtons}>
                                <SemiOvalButton
                                    text={"Continue"}
                                    theme={"dark"}
                                    type={"right"}
                                    size={"middle"}
                                />
                            </div>
                        </div>
                    </form>
                );
            }}
        </Formik>
    );
};

import { useGetRecipesByByIdQuery, usePostSalesCreateMutation, } from "libs/store/recipeApi";
import { TokenGfx } from "components/atoms/TokenGfx/TokenGfx";
import styles from "components/organisms/BuyRecipe/BuyRecipel.module.scss";
import { Divider } from "components/atoms/Divider/Divider";
import { SemiOvalButton } from "components/atoms";
import { getUrlConfig } from "libs";
import { LoginProtectedAction } from "components/atoms/LoginProtectedAction";

import { useEffect, useState } from "react";

const stripeKey = import.meta.env.VITE_STRIPE_PUBKEY as string | undefined;
const hasStripe = stripeKey && stripeKey?.length > 0;

export const BuyRecipe = ({dbId}: { dbId: string }) => {

    const {data: recipeData} = useGetRecipesByByIdQuery({id: dbId}, {skip: !dbId});

    const [getRedirUrl] = usePostSalesCreateMutation();

    //SMELL we avoid importing stripe here to not make requests to the server if not needed, not even type imports
    const [stripe, setStripe] = useState<any>(null);

    useEffect(() => {
        (async function () {
            if (hasStripe) {
                const {loadStripe} = await import('@stripe/stripe-js');
                const stripeInstance = await loadStripe(stripeKey);
                setStripe(stripeInstance);
            }
        })();
    }, []);


    const {frontendBaseUrl} = getUrlConfig();
    console.log("frontendBaseUrl", frontendBaseUrl)
    const quoteAndRedirect = async () => {
        const quote = await getRedirUrl({
            createSaleDto: {
                recipe_id: dbId,
                backlink_url_prefix: frontendBaseUrl
            }
        }).unwrap();

        if (!stripe) throw new Error("unable to load stripe");
        await stripe.redirectToCheckout({
            sessionId: quote.stripe_checkout_session_id,
        });
    };

    const creatorLabel = recipeData?.creator?.name && <>by {recipeData.creator.name}</>;
    return (
        <>
            <div className={styles.RecipeDetail}>
                {recipeData && <>
                    <div>{recipeData.name} {creatorLabel}</div>
                    <Divider/>
                    <TokenGfx recipe={recipeData}/>
                    {!hasStripe && <div>Stipe not configured...</div>}
                    <LoginProtectedAction>
                        <SemiOvalButton disabled={!stripe}
                                        text={"Buy"} onClick={quoteAndRedirect}/>
                    </LoginProtectedAction>
                </>}
            </div>
        </>
    );
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";
import { RootState } from "./store";
import { useAppSelector } from "./hooks";
import { addAuthHeader } from "libs/utils";


export interface AuthState {
    email: string | null;
    access_token: string | null;
    refresh_token: string | null;
    controlled_culinary_influencer_id: string | null;
}

const initialState: AuthState = {
    email: null,
    access_token: null,
    refresh_token: null,
    controlled_culinary_influencer_id: null
};

export const AUTH_SLICE_KEY = "auth";

export const authSlice = createSlice({
    name: AUTH_SLICE_KEY,
    initialState,
    reducers: {
        apiLogin(state, { payload: loginResp }: PayloadAction<AuthState>) {
            // fix rehydrating the state because redux-toolkit uses immutable state and tracks the changes
            // https://github.com/rt2zz/redux-persist/issues/1114#issuecomment-549107922
            console.log("loginConext", loginResp);
            state.access_token = loginResp.access_token;
            state.refresh_token = loginResp.refresh_token;
            state.email = loginResp.email;
            state.controlled_culinary_influencer_id = loginResp.controlled_culinary_influencer_id;
            return state;
        },
        apiLogout(state) {
            // fix rehydrating the state because redux-toolkit uses immutable state and tracks the changes
            // https://github.com/rt2zz/redux-persist/issues/1114#issuecomment-549107922
            state.access_token = initialState.access_token;
            state.refresh_token = initialState.refresh_token;
            state.email = initialState.email;
            state.controlled_culinary_influencer_id = initialState.controlled_culinary_influencer_id;
            return state;
        },
    },
});

export const selectAuth = (state: RootState): AuthState => {
    return state.auth;
};
export const selectAccessToken = (state: RootState): string | null => {
    return selectAuth(state).access_token;
};
export const selectUserEmail = (state: RootState): string | null => {
    return selectAuth(state).email;
};

export const useIsTokenBasedAuthorized = (): boolean => {
    const accessToken = useAppSelector(selectAccessToken);
    return Boolean(accessToken);
};

export const { apiLogin, apiLogout } = authSlice.actions;

export const setAuthTokenToRequest: (
    headers: Headers,
    api: Pick<
        BaseQueryApi,
        "getState" | "extra" | "endpoint" | "type" | "forced"
    >
) => MaybePromise<Headers> = (headers, { getState }) => {
    const rootState = getState() as RootState;
    const token = selectAccessToken(rootState);
    addAuthHeader(headers, token);
    return headers;
};

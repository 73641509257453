import { OnBoardingText } from "components/molecules";

export function DefaultOnboardingText() {
    return <OnBoardingText
        title={"Limited Edition Recipes From Top Culinary Creators"}
        bodyText={
            "Get your favorite recipe as digital collectible in limited edition and become top fan of your culinary creator! Collect your most-loved recipes and create your personal collection, get bonus unlockable content and enjoy your recipe with a secret tipp!"
        }
        secondBodyTitle={
            "If you are a culinary creator rare.recipes is the perfect platform to sell your recipes in limited edition to your top fans. We make unique digital collectibles of your favorite recipes and give you the possibility to connect with your fans and your fans to support your work.\n" +
            "Start now and share your recipes on rare.recipes to monetize your culinary content!\n"
        }
    />;
}
import { Header } from "../../organisms";
import { BuyRecipe } from "components/organisms/BuyRecipe/BuyRecipe";
import { BuyRecipeSuccess } from "components/organisms/BuyRecipe/BuyRecipeSuccess";

export type TRecipeTemplate = {
    id: string;
    saleId?: string
    success?: string
};

export const RecipeBuyTemplate = (props: TRecipeTemplate) => {
    const {id, success, saleId} = props;
    return (
        <>
            <Header/>
            {(!!success && !!saleId)
                ? <BuyRecipeSuccess dbId={id} success={success!} saleId={saleId!}/>
                : <BuyRecipe dbId={id}/>
            }
        </>
    );
};

import { Header } from "../../organisms";
import { RecipeDetail } from "components/organisms/RecipeDetail";

export type TRecipeTemplate = {
	id: string;
};

export const RecipeTemplate = (props: TRecipeTemplate) => {
    const { id } = props;

    return (
        <>
            <Header/>
            <RecipeDetail dbId={id}/>
        </>
    );
};

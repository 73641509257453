import { ConfirmEmailTemplate } from "components/templates/ConfirmEmailTemplate";
import { useLocation, useParams } from "react-router-dom";
import Custom404 from "pages/404";

export default function ConfirmEmail() {
    const {uid} = useParams<{ uid: string | undefined }>();
    const location = useLocation();


    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code") || "";
    const backLink = queryParams.has("backLink")
        ? decodeURIComponent(queryParams.get("backLink") || "")
        : undefined;

    if (!uid) {
        return <Custom404/>;
    }

    return <ConfirmEmailTemplate id={uid} backLink={backLink} code={code}/>;
}

import styles from "components/atoms/ProfileButton/ProfileButton.module.scss";

type TProfile = {
  name?: string; //for potential nice url linkings
  id?: string; //for potential linkings
  profilePictureCIUrl?: string;
};

export const ProfileButton = (props: { profile: TProfile }) => {
  //const { profilePictureCIUrl } = props.profile;
  const profilePictureCIUrl =
    "https://sun9-57.userapi.com/impg/Pt7NbAf3p5J4rmeJXItkuQ8PJnvoVHnRxWdaow/QaQIb-stCeo.jpg?size=281x281&quality=96&sign=245ceaa3e5ebfdc15960a7c1b4c1d235&type=album";
  return profilePictureCIUrl ? (
    <div className={styles.profilePictureContainer}>
      <img
        className={styles.profilePicture}
        src={profilePictureCIUrl}
        alt="fav"
      />
    </div>
  ) : (
    //<> </>
    <>
      <div className={styles.profilePictureContainer}>
        <img
          className={styles.profilePicture}
          // profilePictureCIUrl
          src={
            "https://sun9-57.userapi.com/impg/Pt7NbAf3p5J4rmeJXItkuQ8PJnvoVHnRxWdaow/QaQIb-stCeo.jpg?size=281x281&quality=96&sign=245ceaa3e5ebfdc15960a7c1b4c1d235&type=album"
          }
          alt="fav"
        />
      </div>
    </>
  );
};

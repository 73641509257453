import * as Yup from "yup";
import {
    AdminCreatedCi,
    useGetExternalApiInfoQuery,
    usePostCulinaryInfluencersAdminCreateCulinaryInfluencerMutation,
    usePostExternalApiTriggerCrawlMutation,
} from "libs/store/recipeApi";
import { useEffect, useState } from "react";
import { selectAccessToken, useAppSelector } from "libs/store";
import { getUrlConfig } from "libs/utils";
import { useFormik } from "formik";
import { DragDrop, ThumbnailGenerator, XHRUpload } from "uppy";
import Uppy from '@uppy/core';


function cleanupGptList(text?: string) {
    if (!text) return;
    return text.split("\n")
        .map(line => {
            return line.replace(/^ *- +/, "");
        })
        .join("\n");
}

const YOUTUBE_VIDEO_ID_EXPRESSION = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|shorts\/)|youtu\.be\/)([^?&"'>]+)/;
export const useAdminCreateCIForm = () => {

    const authToken = useAppSelector(selectAccessToken);
    const {publicApiUri} = getUrlConfig();
    const [createCI] = usePostCulinaryInfluencersAdminCreateCulinaryInfluencerMutation();
    const [triggerCrawl] = usePostExternalApiTriggerCrawlMutation();
    const [videoidToCrawl, setVideoIdToCrawl] = useState<string>();
    const [crawlStarted, setCrawlStarted] = useState(false);
    const [transferStarted, setTransferStarted] = useState(false);
    const [crawlMightBeFinished, setCrawlMightBeFinished] = useState(false);


    const getUploadParams = (/*file:any*/) => {
        return {
            url: publicApiUri + "/files/" || "",
            headers: {"Authorization": `Bearer ${authToken}`},
        };
    };

    // called every time a file's `status` changes
    const handleChangeStatus = (file: any,
                                status: any,/*
                                allFiles:any*/) => {
        if (status == "done" && file.xhr) {
            const createdIds: { id: string }[] = JSON.parse(file.xhr.responseText).data
            setFieldValue("raw_token_image_id", createdIds[0].id);
            console.log("createdIds", createdIds);
        }

    };

    // receives array of files that are done uploading when submit button is clicked
    const handleSubmitUF = (successFiles: any[], allFiles: any[]): void => {
        console.log(successFiles.map(f => f.meta));
        allFiles.forEach(f => f.remove());
    };

    const [idResult, setIdResult] = useState<AdminCreatedCi>();

    const {
        data: prefillData,
        refetch: refetchPrefillData,
    } = useGetExternalApiInfoQuery({youtubeVideoId: videoidToCrawl!}, {skip: !transferStarted})

    const initialValues = {
        youtubeVideoUrl: "",
        name: "",
        profilePictureCIUrl: "",
        recipeName: "",
        ingredients: "",
        instructions: "",
        onboardingEmail: "",
        raw_token_image_id: ""
    };

    const validationSchema = Yup.object({
        name: Yup.string()
            .trim()
            .min(2, "Too Short!")
            .max(256, "Too Long!")
            .required("Required"),
        onboardingEmail: Yup.string().trim().email(),
        youtubeVideoUrl: Yup.string()
            .test("test", "please provide a valid youtube URL", (value) => {
                if (!value) return false;
                try {
                    const videoId = extractVideoId(value);
                    console.log("videoId", videoId)
                    return true;
                } catch (e) {
                    return false;
                }
            }),
        raw_token_image_id: Yup.string().min(1).required() //will be set to a uuid after upload
    });


    const onSubmit = async (values: typeof initialValues) => {
        try {
            const idResult = await createCI({
                createCulinaryInfluencerAdminDto: {
                    name: values.name,
                    profilePictureCIUrl: values.profilePictureCIUrl,
                    onboardingEmail: values.onboardingEmail,
                    initialRecipes: [{
                        name: values.recipeName,
                        youtubeVideoId: videoidToCrawl,
                        ingredients: values.ingredients,
                        instructions: values.instructions,
                        raw_token_image_id: values.raw_token_image_id,
                        releaseStatus: "Draft"
                    }]
                }
            }).unwrap();
            setIdResult(idResult);
        } catch (error) {
            console.error(error);
        }
    };

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: onSubmit,
        validationSchema: validationSchema,
    });


    function extractVideoId(url: string): string {
        const match = url.match(YOUTUBE_VIDEO_ID_EXPRESSION);
        if (match) {
            return match[1];
        } else {
            throw new Error("not a youtube url...");
        }
    }

    const triggerCrawlAndWait = () => {
        const videoId = extractVideoId(formik.values.youtubeVideoUrl);
        setCrawlStarted(true);
        try {
            triggerCrawl({youtubeVideoId: videoId});
        } catch (e) {
            console.log("ignoring response error - we are just triggering an async process on the server", e)
        }
        setVideoIdToCrawl(videoId);
        setTimeout(() => {
            setCrawlMightBeFinished(true);
        }, 1000);
    };

    const triggerTransfer = () => {
        if (transferStarted) {
            refetchPrefillData();
        } else {
            setTransferStarted(true);

        }
    };
    const {setFieldValue} = formik;

    const [uppy, setUppy] = useState<Uppy>();

    const [thumbnail, setThumbnail] = useState<{ file: any, preview: string } | null>(null);


    useEffect(() => {
        if (crawlMightBeFinished) {
            console.log("init uppy")
            const uppyInstance = new Uppy({
                autoProceed: true,
                restrictions: {
                    maxNumberOfFiles: 1,
                    allowedFileTypes: ['image/*', 'audio/*', 'video/*'],
                },
            });

            uppyInstance.use(ThumbnailGenerator, {thumbnailWidth: 300, thumbnailHeight: 300});
            uppyInstance.on('thumbnail:generated', (file, preview) => {
                setThumbnail({file, preview});
            });


            uppyInstance.use(XHRUpload, {
                endpoint: publicApiUri + "/files/",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            uppyInstance.on('upload-success', (file, response) => {
                console.log("file", file)
                const createdIds: { id: string }[] = response.body.data;
                setFieldValue('raw_token_image_id', createdIds[0].id);
                console.log('raw_token_image_id', createdIds[0].id);
            });

            uppyInstance.use(DragDrop, {
                id: "uppyContainer",
                width: "100px",
                height: "100px",
                allowMultipleFiles: false,
                replaceTargetContent: true
            })

            setUppy(uppyInstance);

            return () => {
                uppyInstance.close();
            };
        }
    }, [authToken, crawlMightBeFinished, publicApiUri, setFieldValue]);

    useEffect(() => {
        const data = prefillData;
        if (!data) return;
        setFieldValue("name", data.channelName)
        setFieldValue("recipeName", data.recipeTitle)
        setFieldValue("profilePictureCIUrl", data.profileImageUrl)
        setFieldValue("ingredients", cleanupGptList(data.ingredients))
        setFieldValue("instructions", cleanupGptList(data.instructions))

    }, [prefillData, setFieldValue]);

    function isValidVideoUrl() {
        try {
            extractVideoId(formik.values.youtubeVideoUrl)
            return true;
        } catch (e) {
            return false;
        }
    }

    const hasValidVideoUrl = isValidVideoUrl()

    const allowSubmit = formik.dirty && !formik.isValidating && formik.isValid;
    // console.log("formik",formik)
    return {
        createCI,
        onSubmit,
        validationSchema,
        initialValues,
        triggerCrawlAndWait,
        crawlStarted,
        crawlMightBeFinished,
        prefillData,
        triggerTransfer,
        formik,
        getUploadParams,
        idResult,
        handleChangeStatus,
        handleSubmitUF,
        hasValidVideoUrl,
        allowSubmit,
        uppy,
        thumbnail

    };
};

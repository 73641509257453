
export const ConfirmedIcon = () => {
    return (
        <>
            <svg
                width="66"
                height="66"
                viewBox="0 0 66 66"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M31.3597 9.61745C31.8293 7.53834 34.7918 7.53833 35.2614 9.61744L37.4371 19.2494C37.7415 20.5969 39.2878 21.2374 40.4558 20.4998L48.8051 15.2274C50.6074 14.0894 52.7021 16.1841 51.5641 17.9864L46.2917 26.3357C45.5541 27.5037 46.1946 29.05 47.5421 29.3544L57.1741 31.5301C59.2532 31.9997 59.2532 34.9622 57.1741 35.4318L47.5421 37.6075C46.1946 37.9119 45.5541 39.4582 46.2917 40.6262L51.5641 48.9755C52.7021 50.7778 50.6074 52.8725 48.8051 51.7345L40.4558 46.4621C39.2878 45.7245 37.7415 46.365 37.4371 47.7125L35.2614 57.3445C34.7918 59.4236 31.8293 59.4236 31.3597 57.3445L29.184 47.7125C28.8796 46.365 27.3333 45.7245 26.1653 46.4621L17.816 51.7345C16.0137 52.8725 13.919 50.7778 15.057 48.9756L20.3294 40.6262C21.067 39.4582 20.4265 37.9119 19.079 37.6075L9.44704 35.4318C7.36793 34.9622 7.36792 31.9997 9.44703 31.5301L19.079 29.3544C20.4265 29.05 21.067 27.5037 20.3294 26.3357L15.057 17.9864C13.919 16.1841 16.0137 14.0894 17.816 15.2274L26.1653 20.4998C27.3333 21.2374 28.8796 20.5969 29.184 19.2494L31.3597 9.61745Z"
                    fill="#262424"
                />
                <path
                    d="M37.7424 26.9878L36.5118 28.2719L31.5539 33.2298L30.1093 31.8387L28.8252 30.5547L26.3105 33.0693L27.5946 34.3534L30.2698 37.0286L31.5004 38.3126L32.7844 37.0286L39.0265 30.7865L40.3105 29.5024L37.7424 26.9878Z"
                    fill="white"
                />
            </svg>
        </>
    );
};

import styles from "./MenuButton.module.scss";
import classNames from 'classnames';

type MenuProps = {
    isOpen: boolean;
    setOpen: (_: boolean) => void;
}

export const MenuButton = (props: MenuProps) => {
    const { isOpen, setOpen } = props;

    const toggle = () => {
        setOpen(!isOpen)
    };

    return (
        <div className={classNames(styles.hamburgerLines, isOpen && styles.openMenu)} onClick={toggle}>
            <span className={classNames(styles.line, styles.line1)}></span>
            <span className={classNames(styles.line, styles.line2)}></span>
            <span className={classNames(styles.line, styles.line3)}></span>
        </div>
    )
}
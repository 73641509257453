import { Header, RegisterContainer } from "../../organisms";

export const RegisterTemplate = (props: { backLink?: string }) => {
    return (
        <>
            <Header noLogin={true} />
            <RegisterContainer backLink={props.backLink} />
        </>
    );
};

import styles from "./BlogCard.module.scss";

import { Divider, SemiOvalButton } from "components/atoms";

export type TPreviewCard = {
  previewText: string;
  author: string;
  imgUrl: string;
  availableCount: string | number;
};

export const BlogCard = (props: TPreviewCard) => {
  const { previewText, author, imgUrl, availableCount } = props;
  return (
    <div className={styles.cardPreviewBlogWrapper}>
      <div className={styles.cardPreviewBlogContent}>
        <div className={styles.cardPreviewTextWrapper}>
          <p className={styles.cardPreviewText}>
            {previewText}
            <span className={styles.cardPreviewAuthor}>by {author}</span>
          </p>
          <Divider />
        </div>
        <div className={styles.cardPreviewImageWrapper}>
          <div className={styles.cardPreviewImageContainer}>
            <img className={styles.cardPreviewImage} src={imgUrl} />
          </div>
          <div className={styles.cardPreviewImageActionButton}>
            <p className={styles.cardPreviewImageButtonText}>
              {availableCount}
            </p>
            <div className={styles.cardPreviewImageButtonContainer}>
              <SemiOvalButton text="Login" theme="light" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import styles from "./ObboardingContainer.module.scss";

import { useGetCulinaryInfluencersOnboardingByIdAndInviteCodeQuery } from "libs/store/recipeApi";
import {
  BlogCard,
  CookingRecipe,
  FollowDivider,
  PersonalTipp,
  RecipeCard,
  RecipePreview,
  TopFansList,
} from "components/molecules";
import topfansImg from "./topfans.svg";
import { BlackDivider } from "components/atoms";
import { Divider } from "components/atoms/Divider/Divider";
import { getUrl } from "libs";
import { DefaultOnboardingText } from "components/molecules/OnBoardingText/DefaultOnboardingText";

export const OnboardingContainer = (props: {
  onboardingData: { id: string; inviteCode: string };
  registerAction: () => void;
}) => {
  const { id, inviteCode } = props.onboardingData;
  const { registerAction } = props;

  const { data /*isSuccess*/ } =
    useGetCulinaryInfluencersOnboardingByIdAndInviteCodeQuery(
      { id: id, inviteCode },
      { skip: !id || !inviteCode }
    );

  const firstRecipe = data?.recipes?.[0];

  return (
    <div className={styles.OnboardingContainer}>
      {data && <div className={styles.OnboardingHeader}>Hi, {data.name}!</div>}
      <Divider />
      {firstRecipe && (
        <>
          <div className={styles.cardPlacement}>
            <RecipeCard recipe={firstRecipe} creator={data} disableButton/>
          </div>
          <DefaultOnboardingText/>
          <BlackDivider text={"Here is your preview that we prepared"}/>
          <BlogCard
              previewText={firstRecipe.name}
              author={data.name}
              imgUrl={getUrl(firstRecipe)}
              availableCount={"2/10 available"}
          />
          <FollowDivider author="냥숲nyangsoop"/>
          <TopFansList
              imageUrl={topfansImg}
              titleText={"Top Fans"}
              subTitleText={
                "Get your favorite recipe as digital collectible inlimited edition and become top fan of your culinarycreator!"
              }
          />
          <CookingRecipe recipe={firstRecipe}/>
          <PersonalTipp
              titleText={"Personal Tipp"}
              subTitleText={"Buy NFT and get a secret Tipp."}
              imageUrl={getUrl(firstRecipe)}
          />
          <BlackDivider text={"Display Preview"}/>
          <RecipePreview
              titleText={"To edit and publish please login/register"}
              footerText={"This preview will be available until 12.12.2023"}
              imageUrl={getUrl(firstRecipe)}
              registerAction={registerAction}
              recipe={firstRecipe}
              creator={data}
          />
        </>
      )}
    </div>
  );
};

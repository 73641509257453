import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postAuthLogin: build.mutation<
      PostAuthLoginApiResponse,
      PostAuthLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/login`,
        method: "POST",
        body: queryArg.loginPayloadDto,
      }),
    }),
    postAuthConfirmEmail: build.mutation<
      PostAuthConfirmEmailApiResponse,
      PostAuthConfirmEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/confirmEmail`,
        method: "POST",
        body: queryArg.confirmEmailPayloadDto,
      }),
    }),
    postAuthRegister: build.mutation<
      PostAuthRegisterApiResponse,
      PostAuthRegisterApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/register`,
        method: "POST",
        body: queryArg.registerPayloadDto,
      }),
    }),
    postAuthLogout: build.mutation<
      PostAuthLogoutApiResponse,
      PostAuthLogoutApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/logout`,
        method: "POST",
        body: queryArg.logoutRequestDto,
      }),
    }),
    postAuthRefreshAuthToken: build.mutation<
      PostAuthRefreshAuthTokenApiResponse,
      PostAuthRefreshAuthTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/refresh-auth-token`,
        method: "POST",
        body: queryArg.refreshTokenRequestDto,
      }),
    }),
    postAuthResetPassword: build.mutation<
      PostAuthResetPasswordApiResponse,
      PostAuthResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/reset-password`,
        method: "POST",
        body: queryArg.requestWithEmailDto,
      }),
    }),
    postAuthMagicLoginRequest: build.mutation<
      PostAuthMagicLoginRequestApiResponse,
      PostAuthMagicLoginRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/magic-login-request`,
        method: "POST",
        body: queryArg.requestWithEmailDto,
      }),
    }),
    postAuthResetPasswordConfirm: build.mutation<
      PostAuthResetPasswordConfirmApiResponse,
      PostAuthResetPasswordConfirmApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/reset-password/confirm`,
        method: "POST",
        body: queryArg.resetPasswordDto,
      }),
    }),
    postAuthMagicLoginConfirm: build.mutation<
      PostAuthMagicLoginConfirmApiResponse,
      PostAuthMagicLoginConfirmApiArg
    >({
      query: (queryArg) => ({
        url: `/auth/magic-login/confirm`,
        method: "POST",
        body: queryArg.magicLinkLoginDto,
      }),
    }),
    getRecipes: build.query<GetRecipesApiResponse, GetRecipesApiArg>({
      query: () => ({ url: `/recipes` }),
    }),
    getRecipesByById: build.query<
      GetRecipesByByIdApiResponse,
      GetRecipesByByIdApiArg
    >({
      query: (queryArg) => ({ url: `/recipes/by/${queryArg.id}` }),
    }),
    getRecipesMine: build.query<
      GetRecipesMineApiResponse,
      GetRecipesMineApiArg
    >({
      query: () => ({ url: `/recipes/mine` }),
    }),
    postRecipesDetails: build.mutation<
      PostRecipesDetailsApiResponse,
      PostRecipesDetailsApiArg
    >({
      query: (queryArg) => ({
        url: `/recipes/details`,
        method: "POST",
        body: queryArg.recipeIdsDto,
      }),
    }),
    postRecipesCreate: build.mutation<
      PostRecipesCreateApiResponse,
      PostRecipesCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/recipes/create`,
        method: "POST",
        body: queryArg.createRecipeDto,
      }),
    }),
    postRecipesUpdate: build.mutation<
      PostRecipesUpdateApiResponse,
      PostRecipesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/recipes/update`,
        method: "POST",
        body: queryArg.updateRecipeDto,
      }),
    }),
    getCulinaryInfluencersOnboardingByIdAndInviteCode: build.query<
      GetCulinaryInfluencersOnboardingByIdAndInviteCodeApiResponse,
      GetCulinaryInfluencersOnboardingByIdAndInviteCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/CulinaryInfluencers/onboarding/${queryArg.id}/${queryArg.inviteCode}`,
      }),
    }),
    getCulinaryInfluencersById: build.query<
      GetCulinaryInfluencersByIdApiResponse,
      GetCulinaryInfluencersByIdApiArg
    >({
      query: (queryArg) => ({ url: `/CulinaryInfluencers/${queryArg.id}` }),
    }),
    postCulinaryInfluencersAdminCreateCulinaryInfluencer: build.mutation<
      PostCulinaryInfluencersAdminCreateCulinaryInfluencerApiResponse,
      PostCulinaryInfluencersAdminCreateCulinaryInfluencerApiArg
    >({
      query: (queryArg) => ({
        url: `/CulinaryInfluencers/admin-create-culinary-influencer`,
        method: "POST",
        body: queryArg.createCulinaryInfluencerAdminDto,
      }),
    }),
    getFilesGetById: build.query<
      GetFilesGetByIdApiResponse,
      GetFilesGetByIdApiArg
    >({
      query: (queryArg) => ({ url: `/files/get/${queryArg.id}` }),
    }),
    postFiles: build.mutation<PostFilesApiResponse, PostFilesApiArg>({
      query: (queryArg) => ({
        url: `/files`,
        method: "POST",
        body: queryArg.formData,
      }),
    }),
    postFilesAmendCropInfoById: build.mutation<
      PostFilesAmendCropInfoByIdApiResponse,
      PostFilesAmendCropInfoByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/files/amendCropInfo/${queryArg.id}`,
        method: "POST",
        body: queryArg.cropDto,
      }),
    }),
    postFilesNoauth: build.mutation<
      PostFilesNoauthApiResponse,
      PostFilesNoauthApiArg
    >({
      query: (queryArg) => ({
        url: `/files/noauth`,
        method: "POST",
        body: queryArg.formData,
      }),
    }),
    postSalesCreate: build.mutation<
      PostSalesCreateApiResponse,
      PostSalesCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/sales/create`,
        method: "POST",
        body: queryArg.createSaleDto,
      }),
    }),
    postSalesValidate: build.mutation<
      PostSalesValidateApiResponse,
      PostSalesValidateApiArg
    >({
      query: (queryArg) => ({
        url: `/sales/validate`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    postExternalApiTriggerCrawl: build.mutation<
      PostExternalApiTriggerCrawlApiResponse,
      PostExternalApiTriggerCrawlApiArg
    >({
      query: (queryArg) => ({
        url: `/ExternalApi/triggerCrawl`,
        method: "POST",
        params: { youtubeVideoId: queryArg.youtubeVideoId },
      }),
    }),
    getExternalApiInfo: build.query<
      GetExternalApiInfoApiResponse,
      GetExternalApiInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/ExternalApi/info`,
        params: { youtubeVideoId: queryArg.youtubeVideoId },
      }),
    }),
    postSurvey: build.mutation<PostSurveyApiResponse, PostSurveyApiArg>({
      query: (queryArg) => ({
        url: `/survey`,
        method: "POST",
        body: queryArg.surveyPayloadDto,
      }),
    }),
    postSocialCommentAdd: build.mutation<
      PostSocialCommentAddApiResponse,
      PostSocialCommentAddApiArg
    >({
      query: (queryArg) => ({
        url: `/social/comment/add`,
        method: "POST",
        body: queryArg.addCommentPayloadDto,
      }),
    }),
    postSocialFollow: build.mutation<
      PostSocialFollowApiResponse,
      PostSocialFollowApiArg
    >({
      query: (queryArg) => ({
        url: `/social/follow`,
        method: "POST",
        body: queryArg.addFollowPayloadDto,
      }),
    }),
    postSocialLike: build.mutation<
      PostSocialLikeApiResponse,
      PostSocialLikeApiArg
    >({
      query: (queryArg) => ({
        url: `/social/like`,
        method: "POST",
        body: queryArg.addLikePayloadDto,
      }),
    }),
    getSocialLikeMine: build.query<
      GetSocialLikeMineApiResponse,
      GetSocialLikeMineApiArg
    >({
      query: () => ({ url: `/social/like/mine` }),
    }),
    getSocialCommentsByRecipeByRecipeId: build.query<
      GetSocialCommentsByRecipeByRecipeIdApiResponse,
      GetSocialCommentsByRecipeByRecipeIdApiArg
    >({
      query: (queryArg) => ({
        url: `/social/comments/by-recipe/${queryArg.recipeId}`,
      }),
    }),
    getSocialCommentsByCreatorByCreatorId: build.query<
      GetSocialCommentsByCreatorByCreatorIdApiResponse,
      GetSocialCommentsByCreatorByCreatorIdApiArg
    >({
      query: (queryArg) => ({
        url: `/social/comments/by-creator/${queryArg.creatorId}`,
      }),
    }),
    getSocialCommentsByUserByUserId: build.query<
      GetSocialCommentsByUserByUserIdApiResponse,
      GetSocialCommentsByUserByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/social/comments/by-user/${queryArg.userId}`,
      }),
    }),
    getVersion: build.query<GetVersionApiResponse, GetVersionApiArg>({
      query: () => ({ url: `/version` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as recipeApi };
export type PostAuthLoginApiResponse = /** status 200  */ JwtResponseDto;
export type PostAuthLoginApiArg = {
  loginPayloadDto: LoginPayloadDto;
};
export type PostAuthConfirmEmailApiResponse = /** status 200  */ JwtResponseDto;
export type PostAuthConfirmEmailApiArg = {
  confirmEmailPayloadDto: ConfirmEmailPayloadDto;
};
export type PostAuthRegisterApiResponse = /** status 200  */ RegisterResultDto;
export type PostAuthRegisterApiArg = {
  registerPayloadDto: RegisterPayloadDto;
};
export type PostAuthLogoutApiResponse = unknown;
export type PostAuthLogoutApiArg = {
  logoutRequestDto: LogoutRequestDto;
};
export type PostAuthRefreshAuthTokenApiResponse =
  /** status 200  */ JwtResponseDto;
export type PostAuthRefreshAuthTokenApiArg = {
  refreshTokenRequestDto: RefreshTokenRequestDto;
};
export type PostAuthResetPasswordApiResponse = unknown;
export type PostAuthResetPasswordApiArg = {
  requestWithEmailDto: RequestWithEmailDto;
};
export type PostAuthMagicLoginRequestApiResponse = unknown;
export type PostAuthMagicLoginRequestApiArg = {
  requestWithEmailDto: RequestWithEmailDto;
};
export type PostAuthResetPasswordConfirmApiResponse = unknown;
export type PostAuthResetPasswordConfirmApiArg = {
  resetPasswordDto: ResetPasswordDto;
};
export type PostAuthMagicLoginConfirmApiResponse =
  /** status 200  */ JwtResponseDto;
export type PostAuthMagicLoginConfirmApiArg = {
  magicLinkLoginDto: MagicLinkLoginDto;
};
export type GetRecipesApiResponse = /** status 200  */ GetRecipeDto[];
export type GetRecipesApiArg = void;
export type GetRecipesByByIdApiResponse = /** status 200  */ GetRecipeDto;
export type GetRecipesByByIdApiArg = {
  id: string;
};
export type GetRecipesMineApiResponse =
  /** status 200 basic information about a recipe, including its culinary creator */ GetRecipeDto[];
export type GetRecipesMineApiArg = void;
export type PostRecipesDetailsApiResponse =
  /** status 200 basic information about all the recipes, including its culinary creator */ GetRecipeDto[];
export type PostRecipesDetailsApiArg = {
  recipeIdsDto: RecipeIdsDto;
};
export type PostRecipesCreateApiResponse = /** status 200  */ CreatedIdDto;
export type PostRecipesCreateApiArg = {
  createRecipeDto: CreateRecipeDto;
};
export type PostRecipesUpdateApiResponse = unknown;
export type PostRecipesUpdateApiArg = {
  updateRecipeDto: UpdateRecipeDto;
};
export type GetCulinaryInfluencersOnboardingByIdAndInviteCodeApiResponse =
  /** status 200 list of initial recipes and their info. */ GetOnboardingInfoDto;
export type GetCulinaryInfluencersOnboardingByIdAndInviteCodeApiArg = {
  id: string;
  inviteCode: string;
};
export type GetCulinaryInfluencersByIdApiResponse =
  /** status 200  */ GetPublicCulinaryInfluencerInfoDto;
export type GetCulinaryInfluencersByIdApiArg = {
  id: string;
};
export type PostCulinaryInfluencersAdminCreateCulinaryInfluencerApiResponse =
  /** status 200  */ AdminCreatedCi;
export type PostCulinaryInfluencersAdminCreateCulinaryInfluencerApiArg = {
  createCulinaryInfluencerAdminDto: CreateCulinaryInfluencerAdminDto;
};
export type GetFilesGetByIdApiResponse = unknown;
export type GetFilesGetByIdApiArg = {
  id: string;
};
export type PostFilesApiResponse =
  /** status 200 List of DB IDs of the uploaded files */ CreatedIdDto[];
export type PostFilesApiArg = {
  /** List of files to be uploaded */
  formData: FormData;
};
export type PostFilesAmendCropInfoByIdApiResponse =
  /** status 200  */ CreatedIdDto;
export type PostFilesAmendCropInfoByIdApiArg = {
  id: string;
  cropDto: CropDto;
};
export type PostFilesNoauthApiResponse =
  /** status 200 List of DB IDs of the uploaded files */ CreatedIdDto[];
export type PostFilesNoauthApiArg = {
  /** List of files to be uploaded */
  formData: FormData;
};
export type PostSalesCreateApiResponse =
  /** status 200  */ CreateSaleResponseDto;
export type PostSalesCreateApiArg = {
  createSaleDto: CreateSaleDto;
};
export type PostSalesValidateApiResponse = unknown;
export type PostSalesValidateApiArg = {
  body: string;
};
export type PostExternalApiTriggerCrawlApiResponse =
  /** status 200  */ CreatedIdDto;
export type PostExternalApiTriggerCrawlApiArg = {
  youtubeVideoId: string;
};
export type GetExternalApiInfoApiResponse = /** status 200  */ PrefillDataDto;
export type GetExternalApiInfoApiArg = {
  youtubeVideoId: string;
};
export type PostSurveyApiResponse = /** status 200  */ CreatedIdDto;
export type PostSurveyApiArg = {
  surveyPayloadDto: SurveyPayloadDto;
};
export type PostSocialCommentAddApiResponse = /** status 200  */ CreatedIdDto;
export type PostSocialCommentAddApiArg = {
  addCommentPayloadDto: AddCommentPayloadDto;
};
export type PostSocialFollowApiResponse = /** status 200  */ CreatedIdDto;
export type PostSocialFollowApiArg = {
  addFollowPayloadDto: AddFollowPayloadDto;
};
export type PostSocialLikeApiResponse = /** status 200  */ CreatedIdDto;
export type PostSocialLikeApiArg = {
  addLikePayloadDto: AddLikePayloadDto;
};
export type GetSocialLikeMineApiResponse =
  /** status 200 two arrays with their IDs */ GetLikeResponseDto;
export type GetSocialLikeMineApiArg = void;
export type GetSocialCommentsByRecipeByRecipeIdApiResponse =
  /** status 200 List of Comments for a recipe */ GetCommentPayloadDto[];
export type GetSocialCommentsByRecipeByRecipeIdApiArg = {
  recipeId: string;
};
export type GetSocialCommentsByCreatorByCreatorIdApiResponse =
  /** status 200 List of Comments for a creator */ GetCommentPayloadDto[];
export type GetSocialCommentsByCreatorByCreatorIdApiArg = {
  creatorId: string;
};
export type GetSocialCommentsByUserByUserIdApiResponse =
  /** status 200 List of Comments for a user */ GetCommentPayloadDto[];
export type GetSocialCommentsByUserByUserIdApiArg = {
  userId: string;
};
export type GetVersionApiResponse = unknown;
export type GetVersionApiArg = void;
export type JwtResponseDto = {
  id: string;
  email: string;
  controlled_culinary_influencer_id?: string;
  email_confirmed: boolean;
  access_token: string;
  refresh_token: string;
};
export type LoginPayloadDto = {
  email: string;
  password: string;
};
export type ConfirmEmailPayloadDto = {
  id: string;
  confirm_code: string;
};
export type RegisterResultDto = {
  id: string;
  needs_confirm_code: boolean;
  quickLoginInfo?: JwtResponseDto;
};
export type AdminCreatedCi = {
  id: string;
  inviteCode: string;
};
export type RegisterPayloadDto = {
  email: string;
  name: string;
  password: string;
  creatorOnboardingInfo?: AdminCreatedCi | null;
};
export type LogoutRequestDto = {
  access_token: string;
  refresh_token: string;
};
export type RefreshTokenRequestDto = {
  refresh_token: string;
  user_id: string;
};
export type RequestWithEmailDto = {
  email: string;
};
export type ResetPasswordDto = {
  email: string;
  code: string;
  newPassword: string;
};
export type MagicLinkLoginDto = {
  email: string;
  code: string;
};
export type CropDto = {
  zoom?: number;
  cropX?: number;
  cropY?: number;
};
export type ImageInfoDto = {
  crop: CropDto;
  file_id: string;
  path: string;
};
export type GetPublicCulinaryInfluencerInfoDto = {
  id: string;
  name: string;
  signatureUrl?: string;
  profilePictureCIUrl?: string;
};
export type GetRecipeDto = {
  id: string;
  name: string;
  secret_hint?: string;
  instructions: string;
  ingredients: string;
  user_id: string;
  raw_token_image_filename: string;
  tokenImage: ImageInfoDto;
  youtubeVideoId: string;
  releaseStatus: "Draft" | "Released" | "SoldOut";
  user?: string;
  creator?: GetPublicCulinaryInfluencerInfoDto | null;
};
export type RecipeIdsDto = {
  recipe_ids: string[];
};
export type CreatedIdDto = {
  id: string;
};
export type CreateRecipeDto = {
  name: string;
  secret_hint?: string;
  instructions?: string;
  ingredients?: string;
  raw_token_image_id: string;
  youtubeVideoId?: string;
  releaseStatus?: "Draft" | "Released" | "SoldOut";
};
export type UpdateRecipeDto = {
  name: string;
  secret_hint?: string;
  instructions?: string;
  ingredients?: string;
  raw_token_image_id: string;
  youtubeVideoId?: string;
  releaseStatus?: "Draft" | "Released" | "SoldOut";
  id: string;
};
export type GetBasicRecipesDto = {
  id: string;
  name: string;
  secret_hint?: string;
  instructions: string;
  ingredients: string;
  user_id: string;
  raw_token_image_filename: string;
  tokenImage: ImageInfoDto;
  youtubeVideoId: string;
  releaseStatus: "Draft" | "Released" | "SoldOut";
};
export type GetOnboardingInfoDto = {
  id: string;
  name: string;
  signatureUrl?: string;
  profilePictureCIUrl?: string;
  recipes: GetBasicRecipesDto[];
  email: string;
};
export type CreateCulinaryInfluencerAdminDto = {
  name: string;
  onboardingEmail?: string;
  profilePictureCIUrl?: string;
  initialRecipes: CreateRecipeDto[];
};
export type FormData = {};
export type CreateSaleResponseDto = {
  payment_checkout_url: string;
  stripe_checkout_session_id: string;
};
export type CreateSaleDto = {
  recipe_id: string;
  backlink_url_prefix: string;
};
export type PrefillDataDto = {
  videoDescription: string;
  channelDescription?: string;
  profileImageUrl?: string;
  transcript?: string;
  ingredients?: string;
  instructions?: string;
  recipeTitle?: string;
  channelName?: string;
};
export type SurveyPayloadDto = {
  name?: string;
  email?: string;
  favouriteCook?: string;
  role?: string;
  comment?: string;
  notifyMe: boolean;
};
export type AddCommentPayloadDto = {
  recipe_id: string;
  text?: string;
  reaction?: string;
  parent_id?: string;
};
export type AddFollowPayloadDto = {
  culinary_creator_id: string;
  enabled: boolean;
};
export type AddLikePayloadDto = {
  recipe_id: string;
  enabled: boolean;
};
export type GetLikeResponseDto = {
  recipe_ids: string[];
  culinary_creator_ids: string[];
};
export type GetCommentPayloadDto = {
  id: string;
  recipe_id: string;
  text?: string;
  reaction?: string;
  parent_id?: string;
};
export const {
  usePostAuthLoginMutation,
  usePostAuthConfirmEmailMutation,
  usePostAuthRegisterMutation,
  usePostAuthLogoutMutation,
  usePostAuthRefreshAuthTokenMutation,
  usePostAuthResetPasswordMutation,
  usePostAuthMagicLoginRequestMutation,
  usePostAuthResetPasswordConfirmMutation,
  usePostAuthMagicLoginConfirmMutation,
  useGetRecipesQuery,
  useGetRecipesByByIdQuery,
  useGetRecipesMineQuery,
  usePostRecipesDetailsMutation,
  usePostRecipesCreateMutation,
  usePostRecipesUpdateMutation,
  useGetCulinaryInfluencersOnboardingByIdAndInviteCodeQuery,
  useGetCulinaryInfluencersByIdQuery,
  usePostCulinaryInfluencersAdminCreateCulinaryInfluencerMutation,
  useGetFilesGetByIdQuery,
  usePostFilesMutation,
  usePostFilesAmendCropInfoByIdMutation,
  usePostFilesNoauthMutation,
  usePostSalesCreateMutation,
  usePostSalesValidateMutation,
  usePostExternalApiTriggerCrawlMutation,
  useGetExternalApiInfoQuery,
  usePostSurveyMutation,
  usePostSocialCommentAddMutation,
  usePostSocialFollowMutation,
  usePostSocialLikeMutation,
  useGetSocialLikeMineQuery,
  useGetSocialCommentsByRecipeByRecipeIdQuery,
  useGetSocialCommentsByCreatorByCreatorIdQuery,
  useGetSocialCommentsByUserByUserIdQuery,
  useGetVersionQuery,
} = injectedRtkApi;

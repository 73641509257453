import styles from "./ContainerWithBorder.module.scss";
import { ReactNode } from "react";

export type TContainerWithBorder = {
	children: ReactNode;
};

export const ContainerWithBorder = (props: TContainerWithBorder) => {
    const { children } = props;
    return (
        <div className={styles.containerWithBorder}>{children}</div>
    );
};
